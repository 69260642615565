@font-face {
  font-family: Nunito;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/nunito/v22/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNiLXA3ig.ttf") format("truetype");
}

@font-face {
  font-family: Nunito;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/nunito/v22/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNi83A3ig.ttf") format("truetype");
}

@font-face {
  font-family: Nunito;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/nunito/v22/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNirXA3ig.ttf") format("truetype");
}

@font-face {
  font-family: Nunito;
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/nunito/v22/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNic3c3ig.ttf") format("truetype");
}

@font-face {
  font-family: Nunito;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/nunito/v22/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNiSnc3ig.ttf") format("truetype");
}

@font-face {
  font-family: Nunito;
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/nunito/v22/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNiLXc3ig.ttf") format("truetype");
}

@font-face {
  font-family: Nunito;
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/nunito/v22/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNiBHc3ig.ttf") format("truetype");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/nunito/v22/XRXI3I6Li01BKofiOc5wtlZ2di8HDDshRTM.ttf") format("truetype");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/nunito/v22/XRXI3I6Li01BKofiOc5wtlZ2di8HDOUhRTM.ttf") format("truetype");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/nunito/v22/XRXI3I6Li01BKofiOc5wtlZ2di8HDLshRTM.ttf") format("truetype");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/nunito/v22/XRXI3I6Li01BKofiOc5wtlZ2di8HDGUmRTM.ttf") format("truetype");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/nunito/v22/XRXI3I6Li01BKofiOc5wtlZ2di8HDFwmRTM.ttf") format("truetype");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/nunito/v22/XRXI3I6Li01BKofiOc5wtlZ2di8HDDsmRTM.ttf") format("truetype");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/nunito/v22/XRXI3I6Li01BKofiOc5wtlZ2di8HDBImRTM.ttf") format("truetype");
}

body, html {
  font-family: Nunito, sans-serif;
  font-weight: 500;
  overflow: hidden;
}

.transition-enter {
  opacity: .5;
  transition: all 1s ease-out;
  transform: translateY(125%);
}

.transition-enter-active, .transition-exit {
  opacity: 1;
  transition: all 1s ease-out;
  transform: translateY(0);
}

.transition-exit-active {
  opacity: .5;
  transition: all 1s ease-out;
  transform: translateY(-125%);
}

.switch-view-enter {
  opacity: .5;
  transition: all .5s ease-in;
  transform: translateX(100%);
}

.switch-view-enter-active {
  opacity: 1;
  transition: all .5s ease-in;
  transform: translateX(0);
}

.switch-view-exit {
  opacity: 1;
  transition: all .5s ease-out;
  transform: translateX(0);
}

.switch-view-exit-active {
  opacity: .5;
  transform: translateX(-100%);
}

.add-wheel-settings-page-enter {
  opacity: 0;
}

.add-wheel-settings-page-enter-active {
  opacity: 1;
  transition: all .5s ease-in-out .5s;
}

.add-wheel-settings-page-exit {
  opacity: 1;
}

.add-wheel-settings-page-exit-active {
  opacity: 0;
  transition: all .5s ease-in-out;
}

blockquote {
  border-left: 5px solid #ddd;
  margin: 0 0 20px;
  padding: 10px 20px;
  font-size: 17.5px;
}

.rv-xy-plot__axis__line {
  stroke: #697494 !important;
}

.rv-xy-plot__axis__tick__line {
  transform: translateX(2px);
}

.ck-toolbar {
  border: 2px solid #687192 !important;
  border-radius: 10px 10px 0 0 !important;
}

.ck-button {
  color: #687192 !important;
}

.ck-editor__editable {
  min-height: 200px !important;
  max-height: 200px !important;
  color: #687192 !important;
  border: 2px solid #687192 !important;
  border-top: none !important;
  border-radius: 0 0 10px 10px !important;
  overflow-y: auto !important;
}

.ck-editor__editable::-webkit-scrollbar-track {
  background-color: #0000;
}

.ck-editor__editable::-webkit-scrollbar {
  width: 7px;
}

.ck-editor__editable::-webkit-scrollbar-thumb {
  background-color: #787f99;
  border-radius: 7px !important;
}

.ck-button__label {
  font-family: Nunito !important;
}

.ck-button.ck-on {
  background-color: #a4d3f7 !important;
}

.ck-dropdown__button.ck-on {
  background-color: #0000 !important;
}

.ck-dropdown__panel, .ck-list {
  border-radius: 15px !important;
}

.ck-list :first-child button {
  border-radius: 14px 14px 0 0 !important;
}

.ck-list :last-child button {
  border-radius: 0 0 14px 14px !important;
}

.ck-content p {
  font-weight: 500;
  font-size: 16px !important;
}

.ck-content h2 {
  font-weight: 700;
  font-size: 22px !important;
}

.ck-content h2:after {
  content: " ";
  border: 1px solid #b7bfdd;
  margin-top: 3px;
  margin-bottom: 6px;
  display: block;
}

.ck-content h3 {
  font-weight: 700;
  font-size: 20px !important;
}

.ck-content h4 {
  font-weight: 700;
  font-size: 18px !important;
}

button:focus {
  outline: 0 !important;
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  font-family: sans-serif;
  line-height: 1.15;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  color: #212529;
  text-align: left;
  background-color: #fff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  cursor: help;
  text-decoration-skip-ink: none;
  border-bottom: 0;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  background-color: #0000;
  text-decoration: none;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

pre {
  -ms-overflow-style: scrollbar;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  vertical-align: middle;
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  margin-bottom: .5rem;
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  width: 100%;
  max-width: 100%;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  margin-bottom: .5rem;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  cursor: pointer;
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  border: 0;
  border-top: 1px solid #0000001a;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

small, .small {
  font-size: 80%;
  font-weight: 400;
}

mark, .mark {
  background-color: #fcf8e3;
  padding: .2em;
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: 90%;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  color: #6c757d;
  font-size: 80%;
  display: block;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: #6c757d;
  font-size: 90%;
}

code {
  color: #e83e8c;
  word-wrap: break-word;
  font-size: 87.5%;
}

a > code {
  color: inherit;
}

kbd {
  color: #fff;
  background-color: #212529;
  border-radius: .2rem;
  padding: .2rem .4rem;
  font-size: 87.5%;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  color: #212529;
  font-size: 87.5%;
  display: block;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.col {
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.row-cols-1 > * {
  max-width: 100%;
  flex: 0 0 100%;
}

.row-cols-2 > * {
  max-width: 50%;
  flex: 0 0 50%;
}

.row-cols-3 > * {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.row-cols-4 > * {
  max-width: 25%;
  flex: 0 0 25%;
}

.row-cols-5 > * {
  max-width: 20%;
  flex: 0 0 20%;
}

.row-cols-6 > * {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-auto {
  width: auto;
  max-width: 100%;
  flex: none;
}

.col-1 {
  max-width: 8.33333%;
  flex: 0 0 8.33333%;
}

.col-2 {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-3 {
  max-width: 25%;
  flex: 0 0 25%;
}

.col-4 {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.col-5 {
  max-width: 41.6667%;
  flex: 0 0 41.6667%;
}

.col-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

.col-7 {
  max-width: 58.3333%;
  flex: 0 0 58.3333%;
}

.col-8 {
  max-width: 66.6667%;
  flex: 0 0 66.6667%;
}

.col-9 {
  max-width: 75%;
  flex: 0 0 75%;
}

.col-10 {
  max-width: 83.3333%;
  flex: 0 0 83.3333%;
}

.col-11 {
  max-width: 91.6667%;
  flex: 0 0 91.6667%;
}

.col-12 {
  max-width: 100%;
  flex: 0 0 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

@media (min-width: 576px) {
  .col-sm {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-sm-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-sm-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-sm-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-sm-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-sm-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-sm-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-sm-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-sm-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-sm-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-sm-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-sm-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-sm-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-sm-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-sm-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-sm-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-sm-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-sm-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-md-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-md-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-md-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-md-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-md-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-md-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-md-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-md-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-md-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-md-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-md-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-md-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-md-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-md-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-md-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-md-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-md-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-lg-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-lg-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-lg-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-lg-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-lg-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-lg-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-lg-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-lg-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-lg-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-lg-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-lg-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-lg-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-lg-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-lg-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-lg-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-xl-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-xl-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-xl-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-xl-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-xl-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-xl-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-xl-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-xl-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-xl-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xl-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-xl-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-xl-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-xl-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-xl-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-xl-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }
}

.table {
  width: 100%;
  color: #212529;
  margin-bottom: 1rem;
}

.table th, .table td {
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  padding: .75rem;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th, .table-sm td {
  padding: .3rem;
}

.table-bordered, .table-bordered th, .table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th, .table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th, .table-borderless td, .table-borderless thead th, .table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #0000000d;
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: #00000013;
}

.table-primary, .table-primary > th, .table-primary > td {
  background-color: #b8daff;
}

.table-primary th, .table-primary td, .table-primary thead th, .table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover, .table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary, .table-secondary > th, .table-secondary > td {
  background-color: #d6d8db;
}

.table-secondary th, .table-secondary td, .table-secondary thead th, .table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover, .table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success, .table-success > th, .table-success > td {
  background-color: #c3e6cb;
}

.table-success th, .table-success td, .table-success thead th, .table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover, .table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info, .table-info > th, .table-info > td {
  background-color: #bee5eb;
}

.table-info th, .table-info td, .table-info thead th, .table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover, .table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning, .table-warning > th, .table-warning > td {
  background-color: #ffeeba;
}

.table-warning th, .table-warning td, .table-warning thead th, .table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover, .table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger, .table-danger > th, .table-danger > td {
  background-color: #f5c6cb;
}

.table-danger th, .table-danger td, .table-danger thead th, .table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover, .table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light, .table-light > th, .table-light > td {
  background-color: #fdfdfe;
}

.table-light th, .table-light td, .table-light thead th, .table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover, .table-hover .table-light:hover > td, .table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark, .table-dark > th, .table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th, .table-dark td, .table-dark thead th, .table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover, .table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active, .table-active > th, .table-active > td, .table-hover .table-active:hover, .table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
  background-color: #00000013;
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th, .table-dark td, .table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #ffffff0d;
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: #ffffff13;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  display: block;
  overflow-x: auto;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: #0000;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  opacity: 1;
  background-color: #e9ecef;
}

input[type="date"].form-control, input[type="time"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #495057;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file, .form-control-range {
  width: 100%;
  display: block;
}

.col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .375rem 0;
  font-size: 1rem;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  height: calc(1.5em + .5rem + 2px);
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

select.form-control[size], select.form-control[multiple], textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  margin-top: .25rem;
  display: block;
}

.form-row {
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  display: flex;
}

.form-row > .col, .form-row > [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.form-check {
  padding-left: 1.25rem;
  display: block;
  position: relative;
}

.form-check-input {
  margin-top: .3rem;
  margin-left: -1.25rem;
  position: absolute;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  align-items: center;
  margin-right: .75rem;
  padding-left: 0;
  display: inline-flex;
}

.form-check-inline .form-check-input {
  margin-top: 0;
  margin-left: 0;
  margin-right: .3125rem;
  position: static;
}

.valid-feedback {
  width: 100%;
  color: #28a745;
  margin-top: .25rem;
  font-size: 80%;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #28a745e6;
  border-radius: .25rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.form-row > .col > .valid-tooltip, .form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #28a745;
  padding-right: calc(1.5em + .75rem) !important;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  background-position: right 1.5rem center;
  padding-right: 3rem !important;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") right 1.75rem center / calc(.75em + .375rem) calc(.75em + .375rem) no-repeat;
  border-color: #28a745;
  padding-right: calc(.75em + 2.3125rem) !important;
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label:before, .custom-control-input.is-valid ~ .custom-control-label:before {
  border-color: #28a745;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label:before, .custom-control-input.is-valid:checked ~ .custom-control-label:before {
  background-color: #34ce57;
  border-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label:before, .custom-control-input.is-valid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 .2rem #28a74540;
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label:before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label:before, .was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: 80%;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .25rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.form-row > .col > .invalid-tooltip, .form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem) !important;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  background-position: right 1.5rem center;
  padding-right: 3rem !important;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") right 1.75rem center / calc(.75em + .375rem) calc(.75em + .375rem) no-repeat;
  border-color: #dc3545;
  padding-right: calc(.75em + 2.3125rem) !important;
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label:before, .custom-control-input.is-invalid ~ .custom-control-label:before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label:before, .custom-control-input.is-invalid:checked ~ .custom-control-label:before {
  background-color: #e4606d;
  border-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label:before, .custom-control-input.is-invalid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 .2rem #dc354540;
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label:before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label:before, .was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.form-inline {
  flex-flow: wrap;
  align-items: center;
  display: flex;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }

  .form-inline .form-group {
    flex-flow: wrap;
    flex: none;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }

  .form-inline .form-control {
    width: auto;
    vertical-align: middle;
    display: inline-block;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group, .form-inline .custom-select {
    width: auto;
  }

  .form-inline .form-check {
    width: auto;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    display: flex;
  }

  .form-inline .form-check-input {
    flex-shrink: 0;
    margin-top: 0;
    margin-left: 0;
    margin-right: .25rem;
    position: relative;
  }

  .form-inline .custom-control {
    justify-content: center;
    align-items: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.btn.disabled, .btn:disabled {
  opacity: .65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled, fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 .2rem #268fff80;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #268fff80;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 .2rem #828a9180;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #828a9180;
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 .2rem #48b46180;
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #48b46180;
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 .2rem #3ab0c380;
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #3ab0c380;
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 .2rem #deaa0c80;
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #deaa0c80;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 .2rem #e1536180;
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #e1536180;
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 .2rem #d8d9db80;
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #d8d9db80;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 .2rem #52585d80;
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #52585d80;
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: #0000;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: #0000;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: #0000;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: #0000;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: #0000;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: #0000;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: #0000;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: #0000;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.btn-link {
  color: #007bff;
  font-weight: 400;
  text-decoration: none;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.btn-sm, .btn-group-sm > .btn {
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.btn-block {
  width: 100%;
  display: block;
}

.btn-block + .btn-block {
  margin-top: .5rem;
}

input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  position: relative;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup, .dropright, .dropdown, .dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  z-index: 1000;
  float: left;
  min-width: 10rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #00000026;
  border-radius: .25rem;
  margin: .125rem 0 0;
  padding: .5rem 0;
  font-size: 1rem;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown-menu-left {
  left: 0;
  right: auto;
}

.dropdown-menu-right {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-right {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: .125rem;
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: .125rem;
  top: 0;
  left: 100%;
  right: auto;
}

.dropright .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropright .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-toggle:after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: .125rem;
  top: 0;
  left: auto;
  right: 100%;
}

.dropleft .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropleft .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropleft .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  bottom: auto;
  right: auto;
}

.dropdown-divider {
  height: 0;
  border-top: 1px solid #e9ecef;
  margin: .5rem 0;
  overflow: hidden;
}

.dropdown-item {
  width: 100%;
  clear: both;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  padding: .25rem 1.5rem;
  font-weight: 400;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  background-color: #e9ecef;
  text-decoration: none;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  color: #6c757d;
  white-space: nowrap;
  margin-bottom: 0;
  padding: .5rem 1.5rem;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  color: #212529;
  padding: .25rem 1.5rem;
  display: block;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  flex: auto;
  position: relative;
}

.btn-group > .btn:hover, .btn-group-vertical > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after, .dropright .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"], .btn-group-toggle > .btn input[type="checkbox"], .btn-group-toggle > .btn-group > .btn input[type="radio"], .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-control-plaintext, .input-group > .custom-select, .input-group > .custom-file {
  width: 1%;
  min-width: 0;
  flex: auto;
  margin-bottom: 0;
  position: relative;
}

.input-group > .form-control + .form-control, .input-group > .form-control + .custom-select, .input-group > .form-control + .custom-file, .input-group > .form-control-plaintext + .form-control, .input-group > .form-control-plaintext + .custom-select, .input-group > .form-control-plaintext + .custom-file, .input-group > .custom-select + .form-control, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .custom-file, .input-group > .custom-file + .form-control, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus, .input-group > .custom-select:focus, .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:first-child), .input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  align-items: center;
  display: flex;
}

.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label:after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group:not(.has-validation) > .form-control:not(:last-child), .input-group:not(.has-validation) > .custom-select:not(:last-child), .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label, .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label:after, .input-group.has-validation > .form-control:nth-last-child(n+3), .input-group.has-validation > .custom-select:nth-last-child(n+3), .input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label, .input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label:after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend, .input-group-append {
  display: flex;
}

.input-group-prepend .btn, .input-group-append .btn {
  z-index: 2;
  position: relative;
}

.input-group-prepend .btn:focus, .input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn, .input-group-prepend .btn + .input-group-text, .input-group-prepend .input-group-text + .input-group-text, .input-group-prepend .input-group-text + .btn, .input-group-append .btn + .btn, .input-group-append .btn + .input-group-text, .input-group-append .input-group-text + .input-group-text, .input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  align-items: center;
  margin-bottom: 0;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-text input[type="radio"], .input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea), .input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control, .input-group-lg > .custom-select, .input-group-lg > .input-group-prepend > .input-group-text, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-append > .btn {
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.input-group-sm > .form-control:not(textarea), .input-group-sm > .custom-select {
  height: calc(1.5em + .5rem + 2px);
}

.input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.input-group-lg > .custom-select, .input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text, .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn, .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text, .input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn, .input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text, .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  z-index: 1;
  min-height: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  padding-left: 1.5rem;
  display: block;
  position: relative;
}

.custom-control-inline {
  margin-right: 1rem;
  display: inline-flex;
}

.custom-control-input {
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  left: 0;
}

.custom-control-input:checked ~ .custom-control-label:before {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 .2rem #007bff40;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label:before {
  border-color: #80bdff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label:before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label:before, .custom-control-input:disabled ~ .custom-control-label:before {
  background-color: #e9ecef;
}

.custom-control-label {
  vertical-align: top;
  margin-bottom: 0;
  position: relative;
}

.custom-control-label:before {
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
  display: block;
  position: absolute;
  top: .25rem;
  left: -1.5rem;
}

.custom-control-label:after {
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 50% 50% no-repeat;
  display: block;
  position: absolute;
  top: .25rem;
  left: -1.5rem;
}

.custom-checkbox .custom-control-label:before {
  border-radius: .25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:before {
  background-color: #007bff;
  border-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label:before, .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label:before {
  background-color: #007bff80;
}

.custom-radio .custom-control-label:before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: #007bff80;
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label:before {
  width: 1.75rem;
  pointer-events: all;
  border-radius: .5rem;
  left: -2.25rem;
}

.custom-switch .custom-control-label:after {
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: .5rem;
  transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
  top: calc(.25rem + 2px);
  left: calc(2px - 2.25rem);
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label:after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label:after {
  background-color: #fff;
  transform: translateX(.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: #007bff80;
}

.custom-select {
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  color: #495057;
  vertical-align: middle;
  appearance: none;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem 1.75rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: inline-block;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  background-image: none;
  padding-right: .75rem;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + .5rem + 2px);
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  margin-bottom: 0;
  display: inline-block;
  position: relative;
}

.custom-file-input {
  z-index: 2;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  opacity: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label:after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]:after {
  content: attr(data-browse);
}

.custom-file-label {
  z-index: 1;
  height: calc(1.5em + .75rem + 2px);
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-weight: 400;
  line-height: 1.5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.custom-file-label:after {
  z-index: 3;
  height: calc(1.5em + .75rem);
  color: #495057;
  content: "Browse";
  border-left: inherit;
  background-color: #e9ecef;
  border-radius: 0 .25rem .25rem 0;
  padding: .375rem .75rem;
  line-height: 1.5;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #007bff40;
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #007bff40;
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #007bff40;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  margin-top: 0;
  margin-left: .2rem;
  margin-right: .2rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #0000;
  border-width: .5rem;
  border-color: #0000;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  background-color: #dee2e6;
  border-radius: 1rem;
  margin-right: 15px;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label:before, .custom-file-label, .custom-select {
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label:before, .custom-file-label, .custom-select {
    transition: none;
  }
}

.nav {
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: .5rem 1rem;
  display: block;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  border: 1px solid #0000;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  margin-bottom: -1px;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: #0000;
  border-color: #0000;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.nav-pills .nav-link {
  border-radius: .25rem;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link, .nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem;
  display: flex;
  position: relative;
}

.navbar .container, .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  margin-right: 1rem;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  display: inline-block;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav .dropdown-menu {
  float: none;
  position: static;
}

.navbar-text {
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: inline-block;
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row;
  justify-content: flex-start;
}

.navbar-expand > .container, .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  padding-left: 0;
  padding-right: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: .5rem;
  padding-right: .5rem;
}

.navbar-expand > .container, .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand, .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #000000e6;
}

.navbar-light .navbar-nav .nav-link {
  color: #00000080;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #000000b3;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: #0000004d;
}

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
  color: #000000e6;
}

.navbar-light .navbar-toggler {
  color: #00000080;
  border-color: #0000001a;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: #00000080;
}

.navbar-light .navbar-text a, .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: #000000e6;
}

.navbar-dark .navbar-brand, .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff80;
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #ffffffbf;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: #ffffff40;
}

.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: #ffffff80;
  border-color: #ffffff1a;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: #ffffff80;
}

.navbar-dark .navbar-text a, .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #00000020;
  border-radius: .25rem;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-left-radius: calc(.25rem - 1px);
  border-bottom-right-radius: calc(.25rem - 1px);
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  min-height: 1px;
  flex: auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: .75rem;
}

.card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  background-color: #00000008;
  border-bottom: 1px solid #00000020;
  margin-bottom: 0;
  padding: .75rem 1.25rem;
}

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.card-footer {
  background-color: #00000008;
  border-top: 1px solid #00000020;
  padding: .75rem 1.25rem;
}

.card-footer:last-child {
  border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
}

.card-header-tabs {
  border-bottom: 0;
  margin-bottom: -.75rem;
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.card-header-pills {
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.card-img-overlay {
  border-radius: calc(.25rem - 1px);
  padding: 1.25rem;
  position: absolute;
  inset: 0;
}

.card-img, .card-img-top, .card-img-bottom {
  width: 100%;
  flex-shrink: 0;
}

.card-img, .card-img-top {
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.card-img, .card-img-bottom {
  border-bottom-left-radius: calc(.25rem - 1px);
  border-bottom-right-radius: calc(.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: wrap;
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
  }

  .card-deck .card {
    flex: 1 0;
    margin-bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: .75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
    column-gap: 1.25rem;
  }

  .card-columns .card {
    width: 100%;
    display: inline-block;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  background-color: #e9ecef;
  border-radius: .25rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding: .75rem 1rem;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: .5rem;
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  color: #6c757d;
  content: "/";
  padding-right: .5rem;
}

.breadcrumb-item + .breadcrumb-item:hover:before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  border-radius: .25rem;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
  margin-left: -1px;
  padding: .5rem .75rem;
  line-height: 1.25;
  display: block;
  position: relative;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  background-color: #e9ecef;
  border-color: #dee2e6;
  text-decoration: none;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.page-item:first-child .page-link {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
  margin-left: 0;
}

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem;
}

.badge {
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  border-radius: 10rem;
  padding-left: .6em;
  padding-right: .6em;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff80;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #28a74580;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #dc354580;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #343a4080;
}

.jumbotron {
  background-color: #e9ecef;
  border-radius: .3rem;
  margin-bottom: 2rem;
  padding: 2rem 1rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.alert {
  border: 1px solid #0000;
  border-radius: .25rem;
  margin-bottom: 1rem;
  padding: .75rem 1.25rem;
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  z-index: 2;
  color: inherit;
  padding: .75rem 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  height: 1rem;
  background-color: #e9ecef;
  border-radius: .25rem;
  font-size: .75rem;
  line-height: 0;
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  flex-direction: column;
  justify-content: center;
  transition: width .6s;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  align-items: flex-start;
  display: flex;
}

.media-body {
  flex: 1;
}

.list-group {
  border-radius: .25rem;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  background-color: #f8f9fa;
  text-decoration: none;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  background-color: #fff;
  border: 1px solid #00000020;
  padding: .75rem 1.25rem;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  border-top-width: 1px;
  margin-top: -1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: .25rem;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: .25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  border-left-width: 1px;
  margin-left: -1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  color: #000;
  text-shadow: 0 1px #fff;
  opacity: .5;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  background-color: #0000;
  border: 0;
  padding: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  opacity: 0;
  background-color: #ffffffd9;
  background-clip: padding-box;
  border: 1px solid #0000001a;
  border-radius: .25rem;
  flex-basis: 350px;
  font-size: .875rem;
  box-shadow: 0 .25rem .75rem #0000001a;
}

.toast:not(:last-child) {
  margin-bottom: .75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  opacity: 1;
  display: block;
}

.toast.hide {
  display: none;
}

.toast-header {
  color: #6c757d;
  background-color: #ffffffd9;
  background-clip: padding-box;
  border-bottom: 1px solid #0000000d;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
  align-items: center;
  padding: .25rem .75rem;
  display: flex;
}

.toast-body {
  padding: .75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  z-index: 1050;
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.modal-dialog {
  width: auto;
  pointer-events: none;
  margin: .5rem;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  max-height: calc(100% - 1rem);
  display: flex;
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header, .modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - 1rem);
  align-items: center;
  display: flex;
}

.modal-dialog-centered:before {
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
  display: block;
}

.modal-dialog-centered.modal-dialog-scrollable {
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable:before {
  content: none;
}

.modal-content {
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .3rem;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  display: flex;
}

.modal-header .close {
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  flex: auto;
  padding: 1rem;
  position: relative;
}

.modal-footer {
  border-top: 1px solid #dee2e6;
  border-bottom-left-radius: calc(.3rem - 1px);
  border-bottom-right-radius: calc(.3rem - 1px);
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.modal-footer > * {
  margin: .25rem;
}

.modal-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered:before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  z-index: 1070;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  opacity: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
}

.tooltip.show {
  opacity: .9;
}

.tooltip .arrow {
  width: .8rem;
  height: .4rem;
  display: block;
  position: absolute;
}

.tooltip .arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: .4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow:before, .bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-width: .4rem .4rem 0;
  border-top-color: #000;
  top: 0;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 .4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  width: .4rem;
  height: .8rem;
  left: 0;
}

.bs-tooltip-right .arrow:before, .bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000;
  right: 0;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: .4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow:before, .bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000;
  bottom: 0;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 .4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  width: .4rem;
  height: .8rem;
  right: 0;
}

.bs-tooltip-left .arrow:before, .bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000;
  left: 0;
}

.tooltip-inner {
  max-width: 200px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem;
  padding: .25rem .5rem;
}

.popover {
  z-index: 1060;
  max-width: 276px;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .3rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.popover .arrow {
  width: 1rem;
  height: .5rem;
  margin: 0 .3rem;
  display: block;
  position: absolute;
}

.popover .arrow:before, .popover .arrow:after {
  content: "";
  border-style: solid;
  border-color: #0000;
  display: block;
  position: absolute;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: .5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-.5rem - 1px);
}

.bs-popover-top > .arrow:before, .bs-popover-auto[x-placement^="top"] > .arrow:before {
  border-width: .5rem .5rem 0;
  border-top-color: #00000040;
  bottom: 0;
}

.bs-popover-top > .arrow:after, .bs-popover-auto[x-placement^="top"] > .arrow:after {
  border-width: .5rem .5rem 0;
  border-top-color: #fff;
  bottom: 1px;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: .5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
  left: calc(-.5rem - 1px);
}

.bs-popover-right > .arrow:before, .bs-popover-auto[x-placement^="right"] > .arrow:before {
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #00000040;
  left: 0;
}

.bs-popover-right > .arrow:after, .bs-popover-auto[x-placement^="right"] > .arrow:after {
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #fff;
  left: 1px;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: .5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-.5rem - 1px);
}

.bs-popover-bottom > .arrow:before, .bs-popover-auto[x-placement^="bottom"] > .arrow:before {
  border-width: 0 .5rem .5rem;
  border-bottom-color: #00000040;
  top: 0;
}

.bs-popover-bottom > .arrow:after, .bs-popover-auto[x-placement^="bottom"] > .arrow:after {
  border-width: 0 .5rem .5rem;
  border-bottom-color: #fff;
  top: 1px;
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[x-placement^="bottom"] .popover-header:before {
  width: 1rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
  margin-left: -.5rem;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: .5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
  right: calc(-.5rem - 1px);
}

.bs-popover-left > .arrow:before, .bs-popover-auto[x-placement^="left"] > .arrow:before {
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #00000040;
  right: 0;
}

.bs-popover-left > .arrow:after, .bs-popover-auto[x-placement^="left"] > .arrow:after {
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #fff;
  right: 1px;
}

.popover-header {
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  margin-bottom: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  color: #212529;
  padding: .5rem .75rem;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left), .active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right), .active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-left, .carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev, .carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 15;
  justify-content: center;
  margin-left: 15%;
  margin-right: 15%;
  padding-left: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators li {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid #0000;
  border-bottom: 10px solid #0000;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  z-index: 10;
  color: #fff;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  bottom: 20px;
  left: 15%;
  right: 15%;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  width: 2rem;
  height: 2rem;
  vertical-align: -.125em;
  border: .25em solid;
  border-right-color: #0000;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
  display: inline-block;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  width: 2rem;
  height: 2rem;
  vertical-align: -.125em;
  opacity: 0;
  background-color: currentColor;
  border-radius: 50%;
  animation: spinner-grow .75s linear infinite;
  display: inline-block;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus, button.bg-secondary:hover, button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus, button.bg-success:hover, button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus, button.bg-info:hover, button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus, button.bg-warning:hover, button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus, button.bg-danger:hover, button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus, button.bg-light:hover, button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus, button.bg-dark:hover, button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: #0000 !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: .2rem !important;
}

.rounded {
  border-radius: .25rem !important;
}

.rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.rounded-right {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-bottom {
  border-bottom-left-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-left {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-lg {
  border-radius: .3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  width: 100%;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.embed-responsive:before {
  content: "";
  display: block;
}

.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.embed-responsive-21by9:before {
  padding-top: 42.8571%;
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.embed-responsive-4by3:before {
  padding-top: 75%;
}

.embed-responsive-1by1:before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  width: auto;
  height: auto;
  clip: auto;
  white-space: normal;
  position: static;
  overflow: visible;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0, .my-0 {
  margin-top: 0 !important;
}

.mr-0, .mx-0 {
  margin-right: 0 !important;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

.ml-0, .mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.mt-1, .my-1 {
  margin-top: .25rem !important;
}

.mr-1, .mx-1 {
  margin-right: .25rem !important;
}

.mb-1, .my-1 {
  margin-bottom: .25rem !important;
}

.ml-1, .mx-1 {
  margin-left: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.mt-2, .my-2 {
  margin-top: .5rem !important;
}

.mr-2, .mx-2 {
  margin-right: .5rem !important;
}

.mb-2, .my-2 {
  margin-bottom: .5rem !important;
}

.ml-2, .mx-2 {
  margin-left: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3, .my-3 {
  margin-top: 1rem !important;
}

.mr-3, .mx-3 {
  margin-right: 1rem !important;
}

.mb-3, .my-3 {
  margin-bottom: 1rem !important;
}

.ml-3, .mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4, .my-4 {
  margin-top: 1.5rem !important;
}

.mr-4, .mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4, .mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5, .my-5 {
  margin-top: 3rem !important;
}

.mr-5, .mx-5 {
  margin-right: 3rem !important;
}

.mb-5, .my-5 {
  margin-bottom: 3rem !important;
}

.ml-5, .mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0, .py-0 {
  padding-top: 0 !important;
}

.pr-0, .px-0 {
  padding-right: 0 !important;
}

.pb-0, .py-0 {
  padding-bottom: 0 !important;
}

.pl-0, .px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.pt-1, .py-1 {
  padding-top: .25rem !important;
}

.pr-1, .px-1 {
  padding-right: .25rem !important;
}

.pb-1, .py-1 {
  padding-bottom: .25rem !important;
}

.pl-1, .px-1 {
  padding-left: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.pt-2, .py-2 {
  padding-top: .5rem !important;
}

.pr-2, .px-2 {
  padding-right: .5rem !important;
}

.pb-2, .py-2 {
  padding-bottom: .5rem !important;
}

.pl-2, .px-2 {
  padding-left: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3, .py-3 {
  padding-top: 1rem !important;
}

.pr-3, .px-3 {
  padding-right: 1rem !important;
}

.pb-3, .py-3 {
  padding-bottom: 1rem !important;
}

.pl-3, .px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4, .py-4 {
  padding-top: 1.5rem !important;
}

.pr-4, .px-4 {
  padding-right: 1.5rem !important;
}

.pb-4, .py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4, .px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5, .py-5 {
  padding-top: 3rem !important;
}

.pr-5, .px-5 {
  padding-right: 3rem !important;
}

.pb-5, .py-5 {
  padding-bottom: 3rem !important;
}

.pl-5, .px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -.25rem !important;
}

.mt-n1, .my-n1 {
  margin-top: -.25rem !important;
}

.mr-n1, .mx-n1 {
  margin-right: -.25rem !important;
}

.mb-n1, .my-n1 {
  margin-bottom: -.25rem !important;
}

.ml-n1, .mx-n1 {
  margin-left: -.25rem !important;
}

.m-n2 {
  margin: -.5rem !important;
}

.mt-n2, .my-n2 {
  margin-top: -.5rem !important;
}

.mr-n2, .mx-n2 {
  margin-right: -.5rem !important;
}

.mb-n2, .my-n2 {
  margin-bottom: -.5rem !important;
}

.ml-n2, .mx-n2 {
  margin-left: -.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3, .my-n3 {
  margin-top: -1rem !important;
}

.mr-n3, .mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3, .my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3, .mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4, .my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4, .mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4, .my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4, .mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5, .my-n5 {
  margin-top: -3rem !important;
}

.mr-n5, .mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5, .my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5, .mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto, .my-auto {
  margin-top: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}

.mb-auto, .my-auto {
  margin-bottom: auto !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .mt-sm-1, .my-sm-1 {
    margin-top: .25rem !important;
  }

  .mr-sm-1, .mx-sm-1 {
    margin-right: .25rem !important;
  }

  .mb-sm-1, .my-sm-1 {
    margin-bottom: .25rem !important;
  }

  .ml-sm-1, .mx-sm-1 {
    margin-left: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .mt-sm-2, .my-sm-2 {
    margin-top: .5rem !important;
  }

  .mr-sm-2, .mx-sm-2 {
    margin-right: .5rem !important;
  }

  .mb-sm-2, .my-sm-2 {
    margin-bottom: .5rem !important;
  }

  .ml-sm-2, .mx-sm-2 {
    margin-left: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3, .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3, .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3, .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3, .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4, .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4, .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4, .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4, .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5, .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5, .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5, .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5, .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .pt-sm-1, .py-sm-1 {
    padding-top: .25rem !important;
  }

  .pr-sm-1, .px-sm-1 {
    padding-right: .25rem !important;
  }

  .pb-sm-1, .py-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pl-sm-1, .px-sm-1 {
    padding-left: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .pt-sm-2, .py-sm-2 {
    padding-top: .5rem !important;
  }

  .pr-sm-2, .px-sm-2 {
    padding-right: .5rem !important;
  }

  .pb-sm-2, .py-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pl-sm-2, .px-sm-2 {
    padding-left: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3, .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3, .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3, .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3, .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4, .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4, .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4, .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4, .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5, .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5, .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5, .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5, .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -.25rem !important;
  }

  .mt-sm-n1, .my-sm-n1 {
    margin-top: -.25rem !important;
  }

  .mr-sm-n1, .mx-sm-n1 {
    margin-right: -.25rem !important;
  }

  .mb-sm-n1, .my-sm-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-sm-n1, .mx-sm-n1 {
    margin-left: -.25rem !important;
  }

  .m-sm-n2 {
    margin: -.5rem !important;
  }

  .mt-sm-n2, .my-sm-n2 {
    margin-top: -.5rem !important;
  }

  .mr-sm-n2, .mx-sm-n2 {
    margin-right: -.5rem !important;
  }

  .mb-sm-n2, .my-sm-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-sm-n2, .mx-sm-n2 {
    margin-left: -.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3, .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3, .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3, .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3, .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4, .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4, .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4, .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4, .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5, .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5, .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5, .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5, .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0, .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .mt-md-1, .my-md-1 {
    margin-top: .25rem !important;
  }

  .mr-md-1, .mx-md-1 {
    margin-right: .25rem !important;
  }

  .mb-md-1, .my-md-1 {
    margin-bottom: .25rem !important;
  }

  .ml-md-1, .mx-md-1 {
    margin-left: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .mt-md-2, .my-md-2 {
    margin-top: .5rem !important;
  }

  .mr-md-2, .mx-md-2 {
    margin-right: .5rem !important;
  }

  .mb-md-2, .my-md-2 {
    margin-bottom: .5rem !important;
  }

  .ml-md-2, .mx-md-2 {
    margin-left: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3, .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3, .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3, .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3, .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4, .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4, .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4, .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4, .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5, .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5, .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5, .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5, .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0, .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0, .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0, .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .pt-md-1, .py-md-1 {
    padding-top: .25rem !important;
  }

  .pr-md-1, .px-md-1 {
    padding-right: .25rem !important;
  }

  .pb-md-1, .py-md-1 {
    padding-bottom: .25rem !important;
  }

  .pl-md-1, .px-md-1 {
    padding-left: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .pt-md-2, .py-md-2 {
    padding-top: .5rem !important;
  }

  .pr-md-2, .px-md-2 {
    padding-right: .5rem !important;
  }

  .pb-md-2, .py-md-2 {
    padding-bottom: .5rem !important;
  }

  .pl-md-2, .px-md-2 {
    padding-left: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3, .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3, .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3, .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3, .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4, .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4, .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4, .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4, .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5, .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5, .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5, .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5, .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -.25rem !important;
  }

  .mt-md-n1, .my-md-n1 {
    margin-top: -.25rem !important;
  }

  .mr-md-n1, .mx-md-n1 {
    margin-right: -.25rem !important;
  }

  .mb-md-n1, .my-md-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-md-n1, .mx-md-n1 {
    margin-left: -.25rem !important;
  }

  .m-md-n2 {
    margin: -.5rem !important;
  }

  .mt-md-n2, .my-md-n2 {
    margin-top: -.5rem !important;
  }

  .mr-md-n2, .mx-md-n2 {
    margin-right: -.5rem !important;
  }

  .mb-md-n2, .my-md-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-md-n2, .mx-md-n2 {
    margin-left: -.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3, .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3, .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3, .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3, .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4, .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4, .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4, .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4, .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5, .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5, .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5, .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5, .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto, .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .mt-lg-1, .my-lg-1 {
    margin-top: .25rem !important;
  }

  .mr-lg-1, .mx-lg-1 {
    margin-right: .25rem !important;
  }

  .mb-lg-1, .my-lg-1 {
    margin-bottom: .25rem !important;
  }

  .ml-lg-1, .mx-lg-1 {
    margin-left: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .mt-lg-2, .my-lg-2 {
    margin-top: .5rem !important;
  }

  .mr-lg-2, .mx-lg-2 {
    margin-right: .5rem !important;
  }

  .mb-lg-2, .my-lg-2 {
    margin-bottom: .5rem !important;
  }

  .ml-lg-2, .mx-lg-2 {
    margin-left: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3, .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3, .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3, .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3, .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4, .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4, .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4, .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4, .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5, .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5, .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5, .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5, .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .pt-lg-1, .py-lg-1 {
    padding-top: .25rem !important;
  }

  .pr-lg-1, .px-lg-1 {
    padding-right: .25rem !important;
  }

  .pb-lg-1, .py-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pl-lg-1, .px-lg-1 {
    padding-left: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .pt-lg-2, .py-lg-2 {
    padding-top: .5rem !important;
  }

  .pr-lg-2, .px-lg-2 {
    padding-right: .5rem !important;
  }

  .pb-lg-2, .py-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pl-lg-2, .px-lg-2 {
    padding-left: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3, .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3, .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3, .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3, .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4, .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4, .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4, .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4, .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5, .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5, .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5, .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5, .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -.25rem !important;
  }

  .mt-lg-n1, .my-lg-n1 {
    margin-top: -.25rem !important;
  }

  .mr-lg-n1, .mx-lg-n1 {
    margin-right: -.25rem !important;
  }

  .mb-lg-n1, .my-lg-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-lg-n1, .mx-lg-n1 {
    margin-left: -.25rem !important;
  }

  .m-lg-n2 {
    margin: -.5rem !important;
  }

  .mt-lg-n2, .my-lg-n2 {
    margin-top: -.5rem !important;
  }

  .mr-lg-n2, .mx-lg-n2 {
    margin-right: -.5rem !important;
  }

  .mb-lg-n2, .my-lg-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-lg-n2, .mx-lg-n2 {
    margin-left: -.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3, .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3, .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3, .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3, .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4, .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4, .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4, .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4, .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5, .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5, .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5, .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5, .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .mt-xl-1, .my-xl-1 {
    margin-top: .25rem !important;
  }

  .mr-xl-1, .mx-xl-1 {
    margin-right: .25rem !important;
  }

  .mb-xl-1, .my-xl-1 {
    margin-bottom: .25rem !important;
  }

  .ml-xl-1, .mx-xl-1 {
    margin-left: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .mt-xl-2, .my-xl-2 {
    margin-top: .5rem !important;
  }

  .mr-xl-2, .mx-xl-2 {
    margin-right: .5rem !important;
  }

  .mb-xl-2, .my-xl-2 {
    margin-bottom: .5rem !important;
  }

  .ml-xl-2, .mx-xl-2 {
    margin-left: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3, .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3, .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3, .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3, .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4, .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4, .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4, .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4, .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5, .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5, .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5, .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5, .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .pt-xl-1, .py-xl-1 {
    padding-top: .25rem !important;
  }

  .pr-xl-1, .px-xl-1 {
    padding-right: .25rem !important;
  }

  .pb-xl-1, .py-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pl-xl-1, .px-xl-1 {
    padding-left: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .pt-xl-2, .py-xl-2 {
    padding-top: .5rem !important;
  }

  .pr-xl-2, .px-xl-2 {
    padding-right: .5rem !important;
  }

  .pb-xl-2, .py-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pl-xl-2, .px-xl-2 {
    padding-left: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3, .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3, .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3, .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3, .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4, .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4, .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4, .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4, .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5, .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5, .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5, .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5, .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -.25rem !important;
  }

  .mt-xl-n1, .my-xl-n1 {
    margin-top: -.25rem !important;
  }

  .mr-xl-n1, .mx-xl-n1 {
    margin-right: -.25rem !important;
  }

  .mb-xl-n1, .my-xl-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-xl-n1, .mx-xl-n1 {
    margin-left: -.25rem !important;
  }

  .m-xl-n2 {
    margin: -.5rem !important;
  }

  .mt-xl-n2, .my-xl-n2 {
    margin-top: -.5rem !important;
  }

  .mr-xl-n2, .mx-xl-n2 {
    margin-right: -.5rem !important;
  }

  .mb-xl-n2, .my-xl-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-xl-n2, .mx-xl-n2 {
    margin-left: -.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3, .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3, .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3, .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3, .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4, .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4, .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4, .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4, .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5, .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5, .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5, .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5, .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link:after {
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: #0000;
  position: absolute;
  inset: 0;
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: #00000080 !important;
}

.text-white-50 {
  color: #ffffff80 !important;
}

.text-hide {
  font: 0 / 0 a;
  color: #0000;
  text-shadow: none;
  background-color: #0000;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *, :before, :after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #adb5bd;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body, .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td, .table th {
    background-color: #fff !important;
  }

  .table-bordered th, .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark th, .table-dark td, .table-dark thead th, .table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  font-family: sans-serif;
  line-height: 1.15;
}

@-ms-viewport {
  width: device-width;
}

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  color: #212529;
  text-align: left;
  background-color: #fff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  cursor: help;
  border-bottom: 0;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
  text-decoration: none;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]), a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre, code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  -ms-overflow-style: scrollbar;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

th {
  text-align: inherit;
}

label {
  margin-bottom: .5rem;
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  width: 100%;
  max-width: 100%;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  margin-bottom: .5rem;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  cursor: pointer;
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: inherit;
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  border: 0;
  border-top: 1px solid #0000001a;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

small, .small {
  font-size: 80%;
  font-weight: 400;
}

mark, .mark {
  background-color: #fcf8e3;
  padding: .2em;
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: 90%;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  color: #6c757d;
  font-size: 80%;
  display: block;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: #6c757d;
  font-size: 90%;
}

code, kbd, pre, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

code {
  color: #e83e8c;
  word-break: break-word;
  font-size: 87.5%;
}

a > code {
  color: inherit;
}

kbd {
  color: #fff;
  background-color: #212529;
  border-radius: .2rem;
  padding: .2rem .4rem;
  font-size: 87.5%;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  color: #212529;
  font-size: 87.5%;
  display: block;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  width: 100%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.col {
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.col-auto {
  width: auto;
  max-width: none;
  flex: none;
}

.col-1 {
  max-width: 8.33333%;
  flex: 0 0 8.33333%;
}

.col-2 {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-3 {
  max-width: 25%;
  flex: 0 0 25%;
}

.col-4 {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.col-5 {
  max-width: 41.6667%;
  flex: 0 0 41.6667%;
}

.col-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

.col-7 {
  max-width: 58.3333%;
  flex: 0 0 58.3333%;
}

.col-8 {
  max-width: 66.6667%;
  flex: 0 0 66.6667%;
}

.col-9 {
  max-width: 75%;
  flex: 0 0 75%;
}

.col-10 {
  max-width: 83.3333%;
  flex: 0 0 83.3333%;
}

.col-11 {
  max-width: 91.6667%;
  flex: 0 0 91.6667%;
}

.col-12 {
  max-width: 100%;
  flex: 0 0 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

@media (min-width: 576px) {
  .col-sm {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .col-sm-auto {
    width: auto;
    max-width: none;
    flex: none;
  }

  .col-sm-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-sm-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-sm-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-sm-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-sm-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-sm-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-sm-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-sm-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-sm-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-sm-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-sm-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .col-md-auto {
    width: auto;
    max-width: none;
    flex: none;
  }

  .col-md-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-md-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-md-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-md-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-md-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-md-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-md-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-md-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-md-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-md-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-md-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .col-lg-auto {
    width: auto;
    max-width: none;
    flex: none;
  }

  .col-lg-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-lg-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-lg-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-lg-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-lg-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-lg-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-lg-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-lg-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-lg-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .col-xl-auto {
    width: auto;
    max-width: none;
    flex: none;
  }

  .col-xl-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-xl-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-xl-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xl-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-xl-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-xl-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-xl-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-xl-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-xl-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }
}

.table {
  width: 100%;
  max-width: 100%;
  background-color: #0000;
  margin-bottom: 1rem;
}

.table th, .table td {
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  padding: .75rem;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm th, .table-sm td {
  padding: .3rem;
}

.table-bordered, .table-bordered th, .table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th, .table-bordered thead td {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #0000000d;
}

.table-hover tbody tr:hover {
  background-color: #00000013;
}

.table-primary, .table-primary > th, .table-primary > td {
  background-color: #b8daff;
}

.table-hover .table-primary:hover, .table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary, .table-secondary > th, .table-secondary > td {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover, .table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success, .table-success > th, .table-success > td {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover, .table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info, .table-info > th, .table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover, .table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning, .table-warning > th, .table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover, .table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger, .table-danger > th, .table-danger > td {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover, .table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light, .table-light > th, .table-light > td {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover, .table-hover .table-light:hover > td, .table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark, .table-dark > th, .table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover, .table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active, .table-active > th, .table-active > td, .table-hover .table-active:hover, .table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
  background-color: #00000013;
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark th, .table-dark td, .table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #ffffff0d;
}

.table-dark.table-hover tbody tr:hover {
  background-color: #ffffff13;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  display: block;
  overflow-x: auto;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  width: 100%;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

.form-control::-ms-expand {
  background-color: #0000;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  opacity: 1;
  background-color: #e9ecef;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file, .form-control-range {
  width: 100%;
  display: block;
}

.col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  width: 100%;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding-top: .375rem;
  padding-bottom: .375rem;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control, .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text, .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text, .input-group-sm > .input-group-prepend > .form-control-plaintext.btn, .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control, .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text, .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text, .input-group-lg > .input-group-prepend > .form-control-plaintext.btn, .input-group-lg > .input-group-append > .form-control-plaintext.btn {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm, .input-group-sm > .form-control, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]), .input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]), .input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]), .input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]), .input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px);
}

.form-control-lg, .input-group-lg > .form-control, .input-group-lg > .input-group-prepend > .input-group-text, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-append > .btn {
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]), .input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]), .input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]), .input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]), .input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px);
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  margin-top: .25rem;
  display: block;
}

.form-row {
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  display: flex;
}

.form-row > .col, .form-row > [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.form-check {
  padding-left: 1.25rem;
  display: block;
  position: relative;
}

.form-check-input {
  margin-top: .3rem;
  margin-left: -1.25rem;
  position: absolute;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  align-items: center;
  margin-right: .75rem;
  padding-left: 0;
  display: inline-flex;
}

.form-check-inline .form-check-input {
  margin-top: 0;
  margin-left: 0;
  margin-right: .3125rem;
  position: static;
}

.valid-feedback {
  width: 100%;
  color: #28a745;
  margin-top: .25rem;
  font-size: 80%;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #28a745cc;
  border-radius: .2rem;
  margin-top: .1rem;
  padding: .5rem;
  font-size: .875rem;
  line-height: 1;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.was-validated .form-control:valid ~ .valid-feedback, .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .form-control.is-valid ~ .valid-tooltip, .was-validated .custom-select:valid ~ .valid-feedback, .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback, .custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label:before, .custom-control-input.is-valid ~ .custom-control-label:before {
  background-color: #71dd8a;
}

.was-validated .custom-control-input:valid ~ .valid-feedback, .was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback, .custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label:before, .custom-control-input.is-valid:checked ~ .custom-control-label:before {
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label:before, .custom-control-input.is-valid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #28a74540;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label:before, .custom-file-input.is-valid ~ .custom-file-label:before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback, .was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback, .custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 .2rem #28a74540;
}

.invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: 80%;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545cc;
  border-radius: .2rem;
  margin-top: .1rem;
  padding: .5rem;
  font-size: .875rem;
  line-height: 1;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .form-control.is-invalid ~ .invalid-tooltip, .was-validated .custom-select:invalid ~ .invalid-feedback, .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback, .custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label:before, .custom-control-input.is-invalid ~ .custom-control-label:before {
  background-color: #efa2a9;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback, .was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback, .custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label:before, .custom-control-input.is-invalid:checked ~ .custom-control-label:before {
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label:before, .custom-control-input.is-invalid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #dc354540;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label:before, .custom-file-input.is-invalid ~ .custom-file-label:before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback, .was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback, .custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 .2rem #dc354540;
}

.form-inline {
  flex-flow: wrap;
  align-items: center;
  display: flex;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }

  .form-inline .form-group {
    flex-flow: wrap;
    flex: none;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }

  .form-inline .form-control {
    width: auto;
    vertical-align: middle;
    display: inline-block;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group {
    width: auto;
  }

  .form-inline .form-check {
    width: auto;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    display: flex;
  }

  .form-inline .form-check-input {
    margin-top: 0;
    margin-left: 0;
    margin-right: .25rem;
    position: relative;
  }

  .form-inline .custom-control {
    justify-content: center;
    align-items: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

.btn:hover, .btn:focus {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.btn.disabled, .btn:disabled {
  opacity: .65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  background-image: none;
}

a.btn.disabled, fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.btn-outline-primary {
  color: #007bff;
  background-color: #0000;
  background-image: none;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: #0000;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: #0000;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: #0000;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-outline-success {
  color: #28a745;
  background-color: #0000;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: #0000;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-outline-info {
  color: #17a2b8;
  background-color: #0000;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: #0000;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-outline-warning {
  color: #ffc107;
  background-color: #0000;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: #0000;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-outline-danger {
  color: #dc3545;
  background-color: #0000;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: #0000;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: #0000;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: #0000;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-outline-dark {
  color: #343a40;
  background-color: #0000;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: #0000;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.btn-link {
  color: #007bff;
  background-color: #0000;
  font-weight: 400;
}

.btn-link:hover {
  color: #0056b3;
  background-color: #0000;
  border-color: #0000;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  box-shadow: none;
  border-color: #0000;
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.btn-sm, .btn-group-sm > .btn {
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.btn-block {
  width: 100%;
  display: block;
}

.btn-block + .btn-block {
  margin-top: .5rem;
}

input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  transition: opacity .15s linear;
}

.fade.show {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.show {
  display: block;
}

tr.collapse.show {
  display: table-row;
}

tbody.collapse.show {
  display: table-row-group;
}

.collapsing {
  height: 0;
  transition: height .35s;
  position: relative;
  overflow: hidden;
}

.dropup, .dropdown {
  position: relative;
}

.dropdown-toggle:after {
  width: 0;
  height: 0;
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  z-index: 1000;
  float: left;
  min-width: 10rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #00000026;
  border-radius: .25rem;
  margin: .125rem 0 0;
  padding: .5rem 0;
  font-size: 1rem;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: .125rem;
}

.dropup .dropdown-toggle:after {
  width: 0;
  height: 0;
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: .125rem;
}

.dropright .dropdown-toggle:after {
  width: 0;
  height: 0;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-left: .3em solid;
  margin-left: .255em;
  display: inline-block;
}

.dropright .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-toggle:after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: .125rem;
}

.dropleft .dropdown-toggle:after {
  width: 0;
  height: 0;
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropleft .dropdown-toggle:before {
  width: 0;
  height: 0;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropleft .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  border-top: 1px solid #e9ecef;
  margin: .5rem 0;
  overflow: hidden;
}

.dropdown-item {
  width: 100%;
  clear: both;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  padding: .25rem 1.5rem;
  font-weight: 400;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  background-color: #f8f9fa;
  text-decoration: none;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  color: #6c757d;
  white-space: nowrap;
  margin-bottom: 0;
  padding: .5rem 1.5rem;
  font-size: .875rem;
  display: block;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  flex: 0 auto;
  position: relative;
}

.btn-group > .btn:hover, .btn-group-vertical > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-group .btn + .btn, .btn-group .btn + .btn-group, .btn-group .btn-group + .btn, .btn-group .btn-group + .btn-group, .btn-group-vertical .btn + .btn, .btn-group-vertical .btn + .btn-group, .btn-group-vertical .btn-group + .btn, .btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.dropdown-toggle-split:after {
  margin-left: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical .btn, .btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn, .btn-group-vertical > .btn + .btn-group, .btn-group-vertical > .btn-group + .btn, .btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"], .btn-group-toggle > .btn input[type="checkbox"], .btn-group-toggle > .btn-group > .btn input[type="radio"], .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .custom-select, .input-group > .custom-file {
  width: 1%;
  flex: auto;
  margin-bottom: 0;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .custom-select:focus, .input-group > .custom-file:focus {
  z-index: 3;
}

.input-group > .form-control + .form-control, .input-group > .form-control + .custom-select, .input-group > .form-control + .custom-file, .input-group > .custom-select + .form-control, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .custom-file, .input-group > .custom-file + .form-control, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:not(:last-child), .input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child), .input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  align-items: center;
  display: flex;
}

.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label:before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label, .input-group > .custom-file:not(:first-child) .custom-file-label:before {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend, .input-group-append {
  display: flex;
}

.input-group-prepend .btn, .input-group-append .btn {
  z-index: 2;
  position: relative;
}

.input-group-prepend .btn + .btn, .input-group-prepend .btn + .input-group-text, .input-group-prepend .input-group-text + .input-group-text, .input-group-prepend .input-group-text + .btn, .input-group-append .btn + .btn, .input-group-append .btn + .input-group-text, .input-group-append .input-group-text + .input-group-text, .input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  align-items: center;
  margin-bottom: 0;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-text input[type="radio"], .input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text, .input-group > .input-group-append:not(:last-child) > .btn, .input-group > .input-group-append:not(:last-child) > .input-group-text, .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  min-height: 1.5rem;
  padding-left: 1.5rem;
  display: block;
  position: relative;
}

.custom-control-inline {
  margin-right: 1rem;
  display: inline-flex;
}

.custom-control-input {
  z-index: -1;
  opacity: 0;
  position: absolute;
}

.custom-control-input:checked ~ .custom-control-label:before {
  color: #fff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #007bff40;
}

.custom-control-input:active ~ .custom-control-label:before {
  color: #fff;
  background-color: #b3d7ff;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label:before {
  background-color: #e9ecef;
}

.custom-control-label {
  margin-bottom: 0;
}

.custom-control-label:before {
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  user-select: none;
  background-color: #dee2e6;
  display: block;
  position: absolute;
  top: .25rem;
  left: 0;
}

.custom-control-label:after {
  width: 1rem;
  height: 1rem;
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  display: block;
  position: absolute;
  top: .25rem;
  left: 0;
}

.custom-checkbox .custom-control-label:before {
  border-radius: .25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label:before, .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label:before {
  background-color: #007bff80;
}

.custom-radio .custom-control-label:before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  background-color: #007bff;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: #007bff80;
}

.custom-select {
  width: 100%;
  height: calc(2.25rem + 2px);
  color: #495057;
  vertical-align: middle;
  appearance: none;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right .75rem center / 8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem 1.75rem .375rem .75rem;
  line-height: 1.5;
  display: inline-block;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: inset 0 1px 2px #00000013, 0 0 5px #80bdff80;
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  background-image: none;
  padding-right: .75rem;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: .375rem;
  padding-bottom: .375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: .375rem;
  padding-bottom: .375rem;
  font-size: 125%;
}

.custom-file {
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
  display: inline-block;
  position: relative;
}

.custom-file-input {
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  opacity: 0;
  margin: 0;
  position: relative;
}

.custom-file-input:focus ~ .custom-file-control {
  border-color: #80bdff;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.custom-file-input:focus ~ .custom-file-control:before {
  border-color: #80bdff;
}

.custom-file-input:lang(en) ~ .custom-file-label:after {
  content: "Browse";
}

.custom-file-label {
  z-index: 1;
  height: calc(2.25rem + 2px);
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  line-height: 1.5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.custom-file-label:after {
  z-index: 3;
  height: 2.25rem;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 .25rem .25rem 0;
  padding: .375rem .75rem;
  line-height: 1.5;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.nav {
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: .5rem 1rem;
  display: block;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid #0000;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: #0000;
  border-color: #0000;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.nav-pills .nav-link {
  border-radius: .25rem;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  margin-right: 1rem;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  display: inline-block;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav .dropdown-menu {
  float: none;
  position: static;
}

.navbar-text {
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: inline-block;
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: center / 100% 100% no-repeat;
  display: inline-block;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu-right {
    left: auto;
    right: 0;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu-right {
    left: auto;
    right: 0;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    left: auto;
    right: 0;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu-right {
    left: auto;
    right: 0;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

.navbar-expand {
  flex-flow: row;
  justify-content: flex-start;
}

.navbar-expand > .container, .navbar-expand > .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .dropdown-menu-right {
  left: auto;
  right: 0;
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: .5rem;
  padding-right: .5rem;
}

.navbar-expand > .container, .navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
}

.navbar-light .navbar-brand, .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #000000e6;
}

.navbar-light .navbar-nav .nav-link {
  color: #00000080;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #000000b3;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: #0000004d;
}

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
  color: #000000e6;
}

.navbar-light .navbar-toggler {
  color: #00000080;
  border-color: #0000001a;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: #00000080;
}

.navbar-light .navbar-text a, .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: #000000e6;
}

.navbar-dark .navbar-brand, .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff80;
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #ffffffbf;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: #ffffff40;
}

.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: #ffffff80;
  border-color: #ffffff1a;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: #ffffff80;
}

.navbar-dark .navbar-text a, .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #00000020;
  border-radius: .25rem;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.card-body {
  flex: auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: .75rem;
}

.card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  background-color: #00000008;
  border-bottom: 1px solid #00000020;
  margin-bottom: 0;
  padding: .75rem 1.25rem;
}

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  background-color: #00000008;
  border-top: 1px solid #00000020;
  padding: .75rem 1.25rem;
}

.card-footer:last-child {
  border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
}

.card-header-tabs {
  border-bottom: 0;
  margin-bottom: -.75rem;
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.card-header-pills {
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.card-img-overlay {
  padding: 1.25rem;
  position: absolute;
  inset: 0;
}

.card-img {
  width: 100%;
  border-radius: calc(.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-left-radius: calc(.25rem - 1px);
  border-bottom-right-radius: calc(.25rem - 1px);
}

.card-deck {
  flex-direction: column;
  display: flex;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }

  .card-deck .card {
    flex-direction: column;
    flex: 1 0;
    margin-bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
  }
}

.card-group {
  flex-direction: column;
  display: flex;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: wrap;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:first-child .card-img-top, .card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:first-child .card-img-bottom, .card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:last-child .card-img-top, .card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:last-child .card-img-bottom, .card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }

  .card-group > .card:only-child {
    border-radius: .25rem;
  }

  .card-group > .card:only-child .card-img-top, .card-group > .card:only-child .card-header {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .card-group > .card:only-child .card-img-bottom, .card-group > .card:only-child .card-footer {
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .card-group > .card:not(:first-child):not(:last-child):not(:only-child), .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: .75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }

  .card-columns .card {
    width: 100%;
    display: inline-block;
  }
}

.breadcrumb {
  background-color: #e9ecef;
  border-radius: .25rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding: .75rem 1rem;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item:before {
  color: #6c757d;
  content: "/";
  padding-left: .5rem;
  padding-right: .5rem;
  display: inline-block;
}

.breadcrumb-item + .breadcrumb-item:hover:before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  border-radius: .25rem;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
  margin-left: -1px;
  padding: .5rem .75rem;
  line-height: 1.25;
  display: block;
  position: relative;
}

.page-link:hover {
  color: #0056b3;
  background-color: #e9ecef;
  border-color: #dee2e6;
  text-decoration: none;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
  margin-left: 0;
}

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem;
}

.badge {
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  border-radius: 10rem;
  padding-left: .6em;
  padding-right: .6em;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  background-color: #0062cc;
  text-decoration: none;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  background-color: #545b62;
  text-decoration: none;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  background-color: #1e7e34;
  text-decoration: none;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  background-color: #117a8b;
  text-decoration: none;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #212529;
  background-color: #d39e00;
  text-decoration: none;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  background-color: #bd2130;
  text-decoration: none;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-light[href]:hover, .badge-light[href]:focus {
  color: #212529;
  background-color: #dae0e5;
  text-decoration: none;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  background-color: #1d2124;
  text-decoration: none;
}

.jumbotron {
  background-color: #e9ecef;
  border-radius: .3rem;
  margin-bottom: 2rem;
  padding: 2rem 1rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.alert {
  border: 1px solid #0000;
  border-radius: .25rem;
  margin-bottom: 1rem;
  padding: .75rem 1.25rem;
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  color: inherit;
  padding: .75rem 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  height: 1rem;
  background-color: #e9ecef;
  border-radius: .25rem;
  font-size: .75rem;
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: #fff;
  text-align: center;
  background-color: #007bff;
  flex-direction: column;
  justify-content: center;
  transition: width .6s;
  display: flex;
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  align-items: flex-start;
  display: flex;
}

.media-body {
  flex: 1;
}

.list-group {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  color: #495057;
  background-color: #f8f9fa;
  text-decoration: none;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  background-color: #fff;
  border: 1px solid #00000020;
  margin-bottom: -1px;
  padding: .75rem 1.25rem;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.list-group-item:last-child {
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
  margin-bottom: 0;
}

.list-group-item:hover, .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-flush .list-group-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  color: #000;
  text-shadow: 0 1px #fff;
  opacity: .5;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
}

.close:hover, .close:focus {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

button.close {
  -webkit-appearance: none;
  background-color: #0000;
  border: 0;
  padding: 0;
}

.modal-open {
  overflow: hidden;
}

.modal {
  z-index: 1050;
  outline: 0;
  display: none;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  width: auto;
  pointer-events: none;
  margin: .5rem;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  transform: translate(0, -25%);
}

.modal.show .modal-dialog {
  transform: translate(0);
}

.modal-dialog-centered {
  min-height: calc(100% - 1rem);
  align-items: center;
  display: flex;
}

.modal-content {
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .3rem;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  z-index: 1040;
  background-color: #000;
  position: fixed;
  inset: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  display: flex;
}

.modal-header .close {
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  flex: auto;
  padding: 1rem;
  position: relative;
}

.modal-footer {
  border-top: 1px solid #e9ecef;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.tooltip {
  z-index: 1070;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  opacity: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
}

.tooltip.show {
  opacity: .9;
}

.tooltip .arrow {
  width: .8rem;
  height: .4rem;
  display: block;
  position: absolute;
}

.tooltip .arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: .4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow:before, .bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-width: .4rem .4rem 0;
  border-top-color: #000;
  top: 0;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 .4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  width: .4rem;
  height: .8rem;
  left: 0;
}

.bs-tooltip-right .arrow:before, .bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000;
  right: 0;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: .4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow:before, .bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000;
  bottom: 0;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 .4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  width: .4rem;
  height: .8rem;
  right: 0;
}

.bs-tooltip-left .arrow:before, .bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000;
  left: 0;
}

.tooltip-inner {
  max-width: 200px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem;
  padding: .25rem .5rem;
}

.popover {
  z-index: 1060;
  max-width: 276px;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .3rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.popover .arrow {
  width: 1rem;
  height: .5rem;
  margin: 0 .3rem;
  display: block;
  position: absolute;
}

.popover .arrow:before, .popover .arrow:after {
  content: "";
  border-style: solid;
  border-color: #0000;
  display: block;
  position: absolute;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: .5rem;
}

.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc(-.5rem - 1px);
}

.bs-popover-top .arrow:before, .bs-popover-auto[x-placement^="top"] .arrow:before, .bs-popover-top .arrow:after, .bs-popover-auto[x-placement^="top"] .arrow:after {
  border-width: .5rem .5rem 0;
}

.bs-popover-top .arrow:before, .bs-popover-auto[x-placement^="top"] .arrow:before {
  border-top-color: #00000040;
  bottom: 0;
}

.bs-popover-top .arrow:after, .bs-popover-auto[x-placement^="top"] .arrow:after {
  border-top-color: #fff;
  bottom: 1px;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: .5rem;
}

.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
  left: calc(-.5rem - 1px);
}

.bs-popover-right .arrow:before, .bs-popover-auto[x-placement^="right"] .arrow:before, .bs-popover-right .arrow:after, .bs-popover-auto[x-placement^="right"] .arrow:after {
  border-width: .5rem .5rem .5rem 0;
}

.bs-popover-right .arrow:before, .bs-popover-auto[x-placement^="right"] .arrow:before {
  border-right-color: #00000040;
  left: 0;
}

.bs-popover-right .arrow:after, .bs-popover-auto[x-placement^="right"] .arrow:after {
  border-right-color: #fff;
  left: 1px;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: .5rem;
}

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc(-.5rem - 1px);
}

.bs-popover-bottom .arrow:before, .bs-popover-auto[x-placement^="bottom"] .arrow:before, .bs-popover-bottom .arrow:after, .bs-popover-auto[x-placement^="bottom"] .arrow:after {
  border-width: 0 .5rem .5rem;
}

.bs-popover-bottom .arrow:before, .bs-popover-auto[x-placement^="bottom"] .arrow:before {
  border-bottom-color: #00000040;
  top: 0;
}

.bs-popover-bottom .arrow:after, .bs-popover-auto[x-placement^="bottom"] .arrow:after {
  border-bottom-color: #fff;
  top: 1px;
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[x-placement^="bottom"] .popover-header:before {
  width: 1rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
  margin-left: -.5rem;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: .5rem;
}

.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
  right: calc(-.5rem - 1px);
}

.bs-popover-left .arrow:before, .bs-popover-auto[x-placement^="left"] .arrow:before, .bs-popover-left .arrow:after, .bs-popover-auto[x-placement^="left"] .arrow:after {
  border-width: .5rem 0 .5rem .5rem;
}

.bs-popover-left .arrow:before, .bs-popover-auto[x-placement^="left"] .arrow:before {
  border-left-color: #00000040;
  right: 0;
}

.bs-popover-left .arrow:after, .bs-popover-auto[x-placement^="left"] .arrow:after {
  border-left-color: #fff;
  right: 1px;
}

.popover-header {
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  margin-bottom: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  color: #212529;
  padding: .5rem .75rem;
}

.carousel {
  position: relative;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-item {
  width: 100%;
  backface-visibility: hidden;
  perspective: 1000px;
  align-items: center;
  transition: transform .6s, -webkit-transform .6s;
  display: none;
  position: relative;
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next, .carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left, .carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-next.carousel-item-left, .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next, .active.carousel-item-right {
  transform: translateX(100%);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-next, .active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev, .active.carousel-item-left {
  transform: translateX(-100%);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-prev, .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-control-prev, .carousel-control-next {
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 20px;
  height: 20px;
  background: center / 100% 100% no-repeat;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  z-index: 15;
  justify-content: center;
  margin-left: 15%;
  margin-right: 15%;
  padding-left: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

.carousel-indicators li {
  width: 30px;
  height: 3px;
  text-indent: -999px;
  background-color: #ffffff80;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  position: relative;
}

.carousel-indicators li:before {
  width: 100%;
  height: 10px;
  content: "";
  display: inline-block;
  position: absolute;
  top: -10px;
  left: 0;
}

.carousel-indicators li:after {
  width: 100%;
  height: 10px;
  content: "";
  display: inline-block;
  position: absolute;
  bottom: -10px;
  left: 0;
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  z-index: 10;
  color: #fff;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  bottom: 20px;
  left: 15%;
  right: 15%;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus, button.bg-secondary:hover, button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus, button.bg-success:hover, button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus, button.bg-info:hover, button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus, button.bg-warning:hover, button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus, button.bg-danger:hover, button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus, button.bg-light:hover, button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus, button.bg-dark:hover, button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: #0000 !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: .25rem !important;
}

.rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.rounded-right {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-bottom {
  border-bottom-left-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-left {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  width: 100%;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.embed-responsive:before {
  content: "";
  display: block;
}

.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.embed-responsive-21by9:before {
  padding-top: 42.8571%;
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.embed-responsive-4by3:before {
  padding-top: 75%;
}

.embed-responsive-1by1:before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  width: auto;
  height: auto;
  clip: auto;
  white-space: normal;
  clip-path: none;
  position: static;
  overflow: visible;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0, .my-0 {
  margin-top: 0 !important;
}

.mr-0, .mx-0 {
  margin-right: 0 !important;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

.ml-0, .mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.mt-1, .my-1 {
  margin-top: .25rem !important;
}

.mr-1, .mx-1 {
  margin-right: .25rem !important;
}

.mb-1, .my-1 {
  margin-bottom: .25rem !important;
}

.ml-1, .mx-1 {
  margin-left: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.mt-2, .my-2 {
  margin-top: .5rem !important;
}

.mr-2, .mx-2 {
  margin-right: .5rem !important;
}

.mb-2, .my-2 {
  margin-bottom: .5rem !important;
}

.ml-2, .mx-2 {
  margin-left: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3, .my-3 {
  margin-top: 1rem !important;
}

.mr-3, .mx-3 {
  margin-right: 1rem !important;
}

.mb-3, .my-3 {
  margin-bottom: 1rem !important;
}

.ml-3, .mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4, .my-4 {
  margin-top: 1.5rem !important;
}

.mr-4, .mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4, .mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5, .my-5 {
  margin-top: 3rem !important;
}

.mr-5, .mx-5 {
  margin-right: 3rem !important;
}

.mb-5, .my-5 {
  margin-bottom: 3rem !important;
}

.ml-5, .mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0, .py-0 {
  padding-top: 0 !important;
}

.pr-0, .px-0 {
  padding-right: 0 !important;
}

.pb-0, .py-0 {
  padding-bottom: 0 !important;
}

.pl-0, .px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.pt-1, .py-1 {
  padding-top: .25rem !important;
}

.pr-1, .px-1 {
  padding-right: .25rem !important;
}

.pb-1, .py-1 {
  padding-bottom: .25rem !important;
}

.pl-1, .px-1 {
  padding-left: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.pt-2, .py-2 {
  padding-top: .5rem !important;
}

.pr-2, .px-2 {
  padding-right: .5rem !important;
}

.pb-2, .py-2 {
  padding-bottom: .5rem !important;
}

.pl-2, .px-2 {
  padding-left: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3, .py-3 {
  padding-top: 1rem !important;
}

.pr-3, .px-3 {
  padding-right: 1rem !important;
}

.pb-3, .py-3 {
  padding-bottom: 1rem !important;
}

.pl-3, .px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4, .py-4 {
  padding-top: 1.5rem !important;
}

.pr-4, .px-4 {
  padding-right: 1.5rem !important;
}

.pb-4, .py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4, .px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5, .py-5 {
  padding-top: 3rem !important;
}

.pr-5, .px-5 {
  padding-right: 3rem !important;
}

.pb-5, .py-5 {
  padding-bottom: 3rem !important;
}

.pl-5, .px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto, .my-auto {
  margin-top: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}

.mb-auto, .my-auto {
  margin-bottom: auto !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .mt-sm-1, .my-sm-1 {
    margin-top: .25rem !important;
  }

  .mr-sm-1, .mx-sm-1 {
    margin-right: .25rem !important;
  }

  .mb-sm-1, .my-sm-1 {
    margin-bottom: .25rem !important;
  }

  .ml-sm-1, .mx-sm-1 {
    margin-left: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .mt-sm-2, .my-sm-2 {
    margin-top: .5rem !important;
  }

  .mr-sm-2, .mx-sm-2 {
    margin-right: .5rem !important;
  }

  .mb-sm-2, .my-sm-2 {
    margin-bottom: .5rem !important;
  }

  .ml-sm-2, .mx-sm-2 {
    margin-left: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3, .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3, .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3, .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3, .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4, .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4, .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4, .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4, .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5, .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5, .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5, .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5, .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .pt-sm-1, .py-sm-1 {
    padding-top: .25rem !important;
  }

  .pr-sm-1, .px-sm-1 {
    padding-right: .25rem !important;
  }

  .pb-sm-1, .py-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pl-sm-1, .px-sm-1 {
    padding-left: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .pt-sm-2, .py-sm-2 {
    padding-top: .5rem !important;
  }

  .pr-sm-2, .px-sm-2 {
    padding-right: .5rem !important;
  }

  .pb-sm-2, .py-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pl-sm-2, .px-sm-2 {
    padding-left: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3, .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3, .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3, .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3, .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4, .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4, .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4, .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4, .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5, .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5, .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5, .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5, .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0, .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .mt-md-1, .my-md-1 {
    margin-top: .25rem !important;
  }

  .mr-md-1, .mx-md-1 {
    margin-right: .25rem !important;
  }

  .mb-md-1, .my-md-1 {
    margin-bottom: .25rem !important;
  }

  .ml-md-1, .mx-md-1 {
    margin-left: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .mt-md-2, .my-md-2 {
    margin-top: .5rem !important;
  }

  .mr-md-2, .mx-md-2 {
    margin-right: .5rem !important;
  }

  .mb-md-2, .my-md-2 {
    margin-bottom: .5rem !important;
  }

  .ml-md-2, .mx-md-2 {
    margin-left: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3, .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3, .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3, .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3, .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4, .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4, .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4, .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4, .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5, .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5, .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5, .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5, .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0, .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0, .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0, .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .pt-md-1, .py-md-1 {
    padding-top: .25rem !important;
  }

  .pr-md-1, .px-md-1 {
    padding-right: .25rem !important;
  }

  .pb-md-1, .py-md-1 {
    padding-bottom: .25rem !important;
  }

  .pl-md-1, .px-md-1 {
    padding-left: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .pt-md-2, .py-md-2 {
    padding-top: .5rem !important;
  }

  .pr-md-2, .px-md-2 {
    padding-right: .5rem !important;
  }

  .pb-md-2, .py-md-2 {
    padding-bottom: .5rem !important;
  }

  .pl-md-2, .px-md-2 {
    padding-left: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3, .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3, .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3, .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3, .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4, .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4, .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4, .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4, .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5, .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5, .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5, .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5, .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto, .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .mt-lg-1, .my-lg-1 {
    margin-top: .25rem !important;
  }

  .mr-lg-1, .mx-lg-1 {
    margin-right: .25rem !important;
  }

  .mb-lg-1, .my-lg-1 {
    margin-bottom: .25rem !important;
  }

  .ml-lg-1, .mx-lg-1 {
    margin-left: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .mt-lg-2, .my-lg-2 {
    margin-top: .5rem !important;
  }

  .mr-lg-2, .mx-lg-2 {
    margin-right: .5rem !important;
  }

  .mb-lg-2, .my-lg-2 {
    margin-bottom: .5rem !important;
  }

  .ml-lg-2, .mx-lg-2 {
    margin-left: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3, .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3, .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3, .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3, .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4, .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4, .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4, .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4, .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5, .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5, .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5, .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5, .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .pt-lg-1, .py-lg-1 {
    padding-top: .25rem !important;
  }

  .pr-lg-1, .px-lg-1 {
    padding-right: .25rem !important;
  }

  .pb-lg-1, .py-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pl-lg-1, .px-lg-1 {
    padding-left: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .pt-lg-2, .py-lg-2 {
    padding-top: .5rem !important;
  }

  .pr-lg-2, .px-lg-2 {
    padding-right: .5rem !important;
  }

  .pb-lg-2, .py-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pl-lg-2, .px-lg-2 {
    padding-left: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3, .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3, .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3, .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3, .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4, .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4, .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4, .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4, .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5, .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5, .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5, .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5, .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .mt-xl-1, .my-xl-1 {
    margin-top: .25rem !important;
  }

  .mr-xl-1, .mx-xl-1 {
    margin-right: .25rem !important;
  }

  .mb-xl-1, .my-xl-1 {
    margin-bottom: .25rem !important;
  }

  .ml-xl-1, .mx-xl-1 {
    margin-left: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .mt-xl-2, .my-xl-2 {
    margin-top: .5rem !important;
  }

  .mr-xl-2, .mx-xl-2 {
    margin-right: .5rem !important;
  }

  .mb-xl-2, .my-xl-2 {
    margin-bottom: .5rem !important;
  }

  .ml-xl-2, .mx-xl-2 {
    margin-left: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3, .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3, .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3, .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3, .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4, .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4, .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4, .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4, .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5, .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5, .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5, .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5, .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .pt-xl-1, .py-xl-1 {
    padding-top: .25rem !important;
  }

  .pr-xl-1, .px-xl-1 {
    padding-right: .25rem !important;
  }

  .pb-xl-1, .py-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pl-xl-1, .px-xl-1 {
    padding-left: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .pt-xl-2, .py-xl-2 {
    padding-top: .5rem !important;
  }

  .pr-xl-2, .px-xl-2 {
    padding-right: .5rem !important;
  }

  .pb-xl-2, .py-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pl-xl-2, .px-xl-2 {
    padding-left: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3, .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3, .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3, .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3, .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4, .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4, .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4, .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4, .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5, .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5, .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5, .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5, .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-hide {
  font: 0 / 0 a;
  color: #0000;
  text-shadow: none;
  background-color: #0000;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *, :before, :after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body, .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td, .table th {
    background-color: #fff !important;
  }

  .table-bordered th, .table-bordered td {
    border: 1px solid #ddd !important;
  }
}

:root {
  --react-pdf-annotation-layer: 1;
  --annotation-unfocused-field-background: url("data:image/svg+xml;charset=UTF-8,<svg width='1px' height='1px' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill:rgba(0, 54, 255, 0.13);'/></svg>");
  --input-focus-border-color: Highlight;
  --input-focus-outline: 1px solid Canvas;
  --input-unfocused-border-color: transparent;
  --input-disabled-border-color: transparent;
  --input-hover-border-color: black;
  --link-outline: none;
}

@media screen and (forced-colors: active) {
  :root {
    --input-focus-border-color: CanvasText;
    --input-unfocused-border-color: ActiveText;
    --input-disabled-border-color: GrayText;
    --input-hover-border-color: Highlight;
    --link-outline: 1.5px solid LinkText;
  }

  .annotationLayer .textWidgetAnnotation :is(input, textarea):required, .annotationLayer .choiceWidgetAnnotation select:required, .annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input:required {
    outline: 1.5px solid selectedItem;
  }

  .annotationLayer .linkAnnotation:hover {
    -webkit-backdrop-filter: invert();
    backdrop-filter: invert();
  }
}

.annotationLayer {
  pointer-events: none;
  transform-origin: 0 0;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
}

.annotationLayer[data-main-rotation="90"] .norotate {
  transform: rotate(270deg)translateX(-100%);
}

.annotationLayer[data-main-rotation="180"] .norotate {
  transform: rotate(180deg)translate(-100%, -100%);
}

.annotationLayer[data-main-rotation="270"] .norotate {
  transform: rotate(90deg)translateY(-100%);
}

.annotationLayer canvas {
  width: 100%;
  height: 100%;
  position: absolute;
}

.annotationLayer section {
  text-align: initial;
  pointer-events: auto;
  box-sizing: border-box;
  transform-origin: 0 0;
  margin: 0;
  position: absolute;
}

.annotationLayer .linkAnnotation {
  outline: var(--link-outline);
}

.annotationLayer :is(.linkAnnotation, .buttonWidgetAnnotation.pushButton) > a {
  width: 100%;
  height: 100%;
  font-size: 1em;
  position: absolute;
  top: 0;
  left: 0;
}

.annotationLayer :is(.linkAnnotation, .buttonWidgetAnnotation.pushButton) > a:hover {
  opacity: .2;
  background: #ff0;
  box-shadow: 0 2px 10px #ff0;
}

.annotationLayer .textAnnotation img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea), .annotationLayer .choiceWidgetAnnotation select, .annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input {
  background-image: var(--annotation-unfocused-field-background);
  border: 2px solid var(--input-unfocused-border-color);
  box-sizing: border-box;
  font: calc(9px * var(--scale-factor) ) sans-serif;
  height: 100%;
  vertical-align: top;
  width: 100%;
  margin: 0;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):required, .annotationLayer .choiceWidgetAnnotation select:required, .annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input:required {
  outline: 1.5px solid red;
}

.annotationLayer .choiceWidgetAnnotation select option {
  padding: 0;
}

.annotationLayer .buttonWidgetAnnotation.radioButton input {
  border-radius: 50%;
}

.annotationLayer .textWidgetAnnotation textarea {
  resize: none;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea)[disabled], .annotationLayer .choiceWidgetAnnotation select[disabled], .annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input[disabled] {
  border: 2px solid var(--input-disabled-border-color);
  cursor: not-allowed;
  background: none;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):hover, .annotationLayer .choiceWidgetAnnotation select:hover, .annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input:hover {
  border: 2px solid var(--input-hover-border-color);
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):hover, .annotationLayer .choiceWidgetAnnotation select:hover, .annotationLayer .buttonWidgetAnnotation.checkBox input:hover {
  border-radius: 2px;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):focus, .annotationLayer .choiceWidgetAnnotation select:focus {
  border: 2px solid var(--input-focus-border-color);
  outline: var(--input-focus-outline);
  background: none;
  border-radius: 2px;
}

.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) :focus {
  background-color: #0000;
  background-image: none;
}

.annotationLayer .buttonWidgetAnnotation.checkBox :focus {
  border: 2px solid var(--input-focus-border-color);
  outline: var(--input-focus-outline);
  border-radius: 2px;
}

.annotationLayer .buttonWidgetAnnotation.radioButton :focus {
  border: 2px solid var(--input-focus-border-color);
  outline: var(--input-focus-outline);
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before, .annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after, .annotationLayer .buttonWidgetAnnotation.radioButton input:checked:before {
  background-color: CanvasText;
  content: "";
  display: block;
  position: absolute;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before, .annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after {
  height: 80%;
  width: 1px;
  left: 45%;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before {
  transform: rotate(45deg);
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after {
  transform: rotate(-45deg);
}

.annotationLayer .buttonWidgetAnnotation.radioButton input:checked:before {
  height: 50%;
  width: 50%;
  border-radius: 50%;
  top: 20%;
  left: 30%;
}

.annotationLayer .textWidgetAnnotation input.comb {
  padding-left: 2px;
  padding-right: 0;
  font-family: monospace;
}

.annotationLayer .textWidgetAnnotation input.comb:focus {
  width: 103%;
}

.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input {
  appearance: none;
}

.annotationLayer .popupTriggerArea {
  height: 100%;
  width: 100%;
}

.annotationLayer .fileAttachmentAnnotation .popupTriggerArea {
  position: absolute;
}

.annotationLayer .popupWrapper {
  font-size: calc(9px * var(--scale-factor) );
  width: 100%;
  min-width: calc(180px * var(--scale-factor) );
  pointer-events: none;
  position: absolute;
}

.annotationLayer .popup {
  max-width: calc(180px * var(--scale-factor) );
  box-shadow: 0 calc(2px * var(--scale-factor) ) calc(5px * var(--scale-factor) ) #888;
  border-radius: calc(2px * var(--scale-factor) );
  padding: calc(6px * var(--scale-factor) );
  margin-left: calc(5px * var(--scale-factor) );
  cursor: pointer;
  font: message-box;
  white-space: normal;
  word-wrap: break-word;
  pointer-events: auto;
  background-color: #ff9;
  position: absolute;
}

.annotationLayer .popup > * {
  font-size: calc(9px * var(--scale-factor) );
}

.annotationLayer .popup h1 {
  display: inline-block;
}

.annotationLayer .popupDate {
  margin-left: calc(5px * var(--scale-factor) );
  display: inline-block;
}

.annotationLayer .popupContent {
  margin-top: calc(2px * var(--scale-factor) );
  padding-top: calc(2px * var(--scale-factor) );
  border-top: 1px solid #333;
}

.annotationLayer .richText > * {
  white-space: pre-wrap;
  font-size: calc(9px * var(--scale-factor) );
}

.annotationLayer .highlightAnnotation, .annotationLayer .underlineAnnotation, .annotationLayer .squigglyAnnotation, .annotationLayer .strikeoutAnnotation, .annotationLayer .freeTextAnnotation, .annotationLayer .lineAnnotation svg line, .annotationLayer .squareAnnotation svg rect, .annotationLayer .circleAnnotation svg ellipse, .annotationLayer .polylineAnnotation svg polyline, .annotationLayer .polygonAnnotation svg polygon, .annotationLayer .caretAnnotation, .annotationLayer .inkAnnotation svg polyline, .annotationLayer .stampAnnotation, .annotationLayer .fileAttachmentAnnotation {
  cursor: pointer;
}

.annotationLayer section svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.annotationLayer .annotationTextContent {
  width: 100%;
  height: 100%;
  opacity: 0;
  color: #0000;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  position: absolute;
}

.annotationLayer .annotationTextContent span {
  width: 100%;
  display: inline-block;
}

:root {
  --react-pdf-text-layer: 1;
  --highlight-bg-color: #b400aa;
  --highlight-selected-bg-color: #006400;
}

@media screen and (forced-colors: active) {
  :root {
    --highlight-bg-color: Highlight;
    --highlight-selected-bg-color: ButtonText;
  }
}

[data-main-rotation="90"] {
  transform: rotate(90deg)translateY(-100%);
}

[data-main-rotation="180"] {
  transform: rotate(180deg)translate(-100%, -100%);
}

[data-main-rotation="270"] {
  transform: rotate(270deg)translateX(-100%);
}

.textLayer {
  text-align: initial;
  text-size-adjust: none;
  forced-color-adjust: none;
  transform-origin: 0 0;
  z-index: 2;
  line-height: 1;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.textLayer :is(span, br) {
  color: #0000;
  white-space: pre;
  cursor: text;
  transform-origin: 0 0;
  margin: 0;
  position: absolute;
}

.textLayer span.markedContent {
  height: 0;
  top: 0;
}

.textLayer .highlight {
  background-color: var(--highlight-bg-color);
  border-radius: 4px;
  margin: -1px;
  padding: 1px;
}

.textLayer .highlight.appended {
  position: initial;
}

.textLayer .highlight.begin {
  border-radius: 4px 0 0 4px;
}

.textLayer .highlight.end {
  border-radius: 0 4px 4px 0;
}

.textLayer .highlight.middle {
  border-radius: 0;
}

.textLayer .highlight.selected {
  background-color: var(--highlight-selected-bg-color);
}

.textLayer br::selection {
  background: none;
}

.textLayer .endOfContent {
  z-index: -1;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  display: block;
  position: absolute;
  inset: 100% 0 0;
}

.textLayer .endOfContent.active {
  top: 0;
}

.uppy-Root {
  box-sizing: border-box;
  color: #333;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Segoe UI Symbol, Segoe UI Emoji, Apple Color Emoji, Roboto, Helvetica, Arial, sans-serif;
  line-height: 1;
  position: relative;
}

[dir="rtl"] .uppy-Root, .uppy-Root[dir="rtl"] {
  text-align: right;
}

.uppy-Root *, .uppy-Root :before, .uppy-Root :after {
  box-sizing: inherit;
}

.uppy-Root [hidden] {
  display: none;
}

.uppy-u-reset {
  all: initial;
  box-sizing: border-box;
  appearance: none;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Segoe UI Symbol, Segoe UI Emoji, Apple Color Emoji, Roboto, Helvetica, Arial, sans-serif;
  line-height: 1;
}

[dir="rtl"] .uppy-u-reset {
  text-align: right;
}

.uppy-c-textInput {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 6px 8px;
  font-size: 14px;
  line-height: 1.5;
}

.uppy-size--md .uppy-c-textInput {
  padding: 8px 10px;
}

.uppy-c-textInput:focus {
  border-color: #2275d799;
  outline: none;
  box-shadow: 0 0 0 3px #2275d726;
}

[data-uppy-theme="dark"] .uppy-c-textInput {
  color: #eaeaea;
  background-color: #333;
  border-color: #333;
}

[data-uppy-theme="dark"] .uppy-c-textInput:focus {
  box-shadow: none;
  border-color: #525252;
}

.uppy-c-icon {
  max-width: 100%;
  max-height: 100%;
  fill: currentColor;
  display: inline-block;
  overflow: hidden;
}

.uppy-c-btn {
  font-weight: 500;
  font-size: inherit;
  color: inherit;
  white-space: nowrap;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  line-height: 1;
  transition-property: background-color, color;
  transition-duration: .3s;
  display: inline-flex;
}

[dir="rtl"] .uppy-c-btn {
  text-align: center;
}

.uppy-c-btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.uppy-c-btn::-moz-focus-inner {
  border: 0;
}

.uppy-c-btn-primary {
  color: #fff;
  background-color: #2275d7;
  border-radius: 4px;
  padding: 10px 18px;
  font-size: 14px;
}

.uppy-c-btn-primary:hover {
  background-color: #1b5dab;
}

.uppy-c-btn-primary:focus {
  outline: none;
  box-shadow: 0 0 0 3px #2275d766;
}

.uppy-size--md .uppy-c-btn-primary {
  padding: 13px 22px;
}

[data-uppy-theme="dark"] .uppy-c-btn-primary {
  color: #eaeaea;
}

[data-uppy-theme="dark"] .uppy-c-btn-primary:focus {
  outline: none;
}

[data-uppy-theme="dark"] .uppy-c-btn-primary::-moz-focus-inner {
  border: 0;
}

[data-uppy-theme="dark"] .uppy-c-btn-primary:focus {
  box-shadow: 0 0 0 2px #aae1ffd9;
}

.uppy-c-btn-link {
  color: #525252;
  background-color: #0000;
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 1;
}

.uppy-c-btn-link:hover {
  color: #333;
}

.uppy-c-btn-link:focus {
  outline: none;
  box-shadow: 0 0 0 3px #2275d740;
}

.uppy-size--md .uppy-c-btn-link {
  padding: 13px 18px;
}

[data-uppy-theme="dark"] .uppy-c-btn-link {
  color: #eaeaea;
}

[data-uppy-theme="dark"] .uppy-c-btn-link:focus {
  outline: none;
}

[data-uppy-theme="dark"] .uppy-c-btn-link::-moz-focus-inner {
  border: 0;
}

[data-uppy-theme="dark"] .uppy-c-btn-link:focus {
  box-shadow: 0 0 0 2px #aae1ffd9;
}

[data-uppy-theme="dark"] .uppy-c-btn-link:hover {
  color: #939393;
}

.uppy-Informer {
  z-index: 1005;
  text-align: center;
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
}

.uppy-Informer span > div {
  margin-bottom: 6px;
}

.uppy-Informer-animated {
  z-index: -1000;
  opacity: 0;
  transition: all .3s ease-in;
  transform: translateY(350%);
}

.uppy-Informer p {
  max-width: 90%;
  color: #fff;
  background-color: #757575;
  border-radius: 18px;
  margin: 0;
  padding: 6px 15px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  display: inline-block;
}

.uppy-size--md .uppy-Informer p {
  max-width: 500px;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 1.3;
}

[data-uppy-theme="dark"] .uppy-Informer p {
  background-color: #333;
}

[dir="ltr"] .uppy-Informer p span {
  left: 3px;
}

[dir="rtl"] .uppy-Informer p span {
  right: 3px;
}

[dir="ltr"] .uppy-Informer p span {
  margin-left: -1px;
}

[dir="rtl"] .uppy-Informer p span {
  margin-right: -1px;
}

.uppy-Informer p span {
  width: 13px;
  height: 13px;
  color: #525252;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 50%;
  font-size: 10px;
  line-height: 12px;
  display: inline-block;
  position: relative;
  top: -1px;
}

.uppy-Informer p span:hover {
  cursor: help;
}

.uppy-Informer p span:after {
  word-wrap: break-word;
  line-height: 1.3;
}

.uppy-Root [aria-label][role~="tooltip"] {
  position: relative;
}

.uppy-Root [aria-label][role~="tooltip"]:before, .uppy-Root [aria-label][role~="tooltip"]:after {
  z-index: 10;
  box-sizing: border-box;
  transform-origin: top;
  backface-visibility: hidden;
  opacity: 0;
  transition: all var(--microtip-transition-duration, .18s) var(--microtip-transition-easing, ease-in-out) var(--microtip-transition-delay, 0s);
  pointer-events: none;
  will-change: transform;
  position: absolute;
  transform: translate3d(0, 0, 0);
}

.uppy-Root [aria-label][role~="tooltip"]:before {
  content: "";
  background-size: 100% !important;
}

.uppy-Root [aria-label][role~="tooltip"]:after {
  box-sizing: content-box;
  color: #fff;
  font-weight: var(--microtip-font-weight, normal);
  font-size: var(--microtip-font-size, 13px);
  white-space: nowrap;
  text-transform: var(--microtip-text-transform, none);
  content: attr(aria-label);
  background: #111111e6;
  border-radius: 4px;
  padding: .5em 1em;
}

.uppy-Root [aria-label][role~="tooltip"]:hover:before, .uppy-Root [aria-label][role~="tooltip"]:hover:after, .uppy-Root [aria-label][role~="tooltip"]:focus:before, .uppy-Root [aria-label][role~="tooltip"]:focus:after {
  opacity: 1;
  pointer-events: auto;
}

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]:before {
  width: 18px;
  height: 6px;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%280%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  margin-bottom: 5px;
  bottom: 100%;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]:after {
  margin-bottom: 11px;
  bottom: 100%;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]:hover:before, .uppy-Root [role~="tooltip"][data-microtip-position="top"]:hover:after {
  transform: translate3d(-50%, -5px, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="top-left"]:after {
  bottom: 100%;
  transform: translate3d(calc(16px - 100%), 0, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="top-left"]:hover:after {
  transform: translate3d(calc(16px - 100%), -5px, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="top-right"]:after {
  bottom: 100%;
  transform: translate3d(-16px, 0, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="top-right"]:hover:after {
  transform: translate3d(-16px, -5px, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]:before {
  width: 18px;
  height: 6px;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%28180%2018%206%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  margin-top: 5px;
  margin-bottom: 0;
  top: 100%;
  bottom: auto;
  left: 50%;
  transform: translate3d(-50%, -10px, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]:after {
  margin-top: 11px;
  top: 100%;
  left: 50%;
  transform: translate3d(-50%, -10px, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]:hover:before, .uppy-Root [role~="tooltip"][data-microtip-position="bottom"]:hover:after {
  transform: translate3d(-50%, 0, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="bottom-left"]:after {
  top: 100%;
  transform: translate3d(calc(16px - 100%), -10px, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="bottom-left"]:hover:after {
  transform: translate3d(calc(16px - 100%), 0, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="bottom-right"]:after {
  top: 100%;
  transform: translate3d(-16px, -10px, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="bottom-right"]:hover:after {
  transform: translate3d(-16px, 0, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="left"]:before, .uppy-Root [role~="tooltip"][data-microtip-position="left"]:after {
  inset: 50% 100% auto auto;
  transform: translate3d(10px, -50%, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="left"]:before {
  width: 6px;
  height: 18px;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%28-90%2018%2018%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  margin-bottom: 0;
  margin-right: 5px;
}

.uppy-Root [role~="tooltip"][data-microtip-position="left"]:after {
  margin-right: 11px;
}

.uppy-Root [role~="tooltip"][data-microtip-position="left"]:hover:before, .uppy-Root [role~="tooltip"][data-microtip-position="left"]:hover:after {
  transform: translate3d(0, -50%, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="right"]:before, .uppy-Root [role~="tooltip"][data-microtip-position="right"]:after {
  top: 50%;
  bottom: auto;
  left: 100%;
  transform: translate3d(-10px, -50%, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="right"]:before {
  width: 6px;
  height: 18px;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%2890%206%206%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  margin-bottom: 0;
  margin-left: 5px;
}

.uppy-Root [role~="tooltip"][data-microtip-position="right"]:after {
  margin-left: 11px;
}

.uppy-Root [role~="tooltip"][data-microtip-position="right"]:hover:before, .uppy-Root [role~="tooltip"][data-microtip-position="right"]:hover:after {
  transform: translate3d(0, -50%, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-size="small"]:after {
  width: 80px;
  white-space: initial;
}

.uppy-Root [role~="tooltip"][data-microtip-size="medium"]:after {
  width: 150px;
  white-space: initial;
}

.uppy-Root [role~="tooltip"][data-microtip-size="large"]:after {
  width: 260px;
  white-space: initial;
}

.uppy-StatusBar {
  z-index: 1001;
  height: 46px;
  color: #fff;
  background-color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 40px;
  transition: height .2s;
  display: flex;
  position: relative;
}

[data-uppy-theme="dark"] .uppy-StatusBar {
  background-color: #1f1f1f;
}

.uppy-StatusBar:before {
  width: 100%;
  height: 2px;
  content: "";
  background-color: #eaeaea;
  position: absolute;
  inset: 0;
}

[data-uppy-theme="dark"] .uppy-StatusBar:before {
  background-color: #757575;
}

.uppy-StatusBar[aria-hidden="true"] {
  height: 0;
  overflow-y: hidden;
}

.uppy-StatusBar.is-complete .uppy-StatusBar-progress {
  background-color: #1bb240;
}

.uppy-StatusBar.is-error .uppy-StatusBar-progress {
  background-color: #e32437;
}

.uppy-StatusBar.is-complete .uppy-StatusBar-statusIndicator {
  color: #1bb240;
}

.uppy-StatusBar.is-error .uppy-StatusBar-statusIndicator {
  color: #e32437;
}

.uppy-StatusBar:not([aria-hidden="true"]).is-waiting {
  height: 65px;
  background-color: #fff;
  border-top: 1px solid #eaeaea;
}

[data-uppy-theme="dark"] .uppy-StatusBar:not([aria-hidden="true"]).is-waiting {
  background-color: #1f1f1f;
  border-top: 1px solid #333;
}

.uppy-StatusBar-progress {
  z-index: 1001;
  height: 2px;
  background-color: #2275d7;
  transition: background-color, width .3s ease-out;
  position: absolute;
}

.uppy-StatusBar-progress.is-indeterminate {
  background-image: linear-gradient(45deg, #0000004d 25%, #0000 25% 50%, #0000004d 50% 75%, #0000 75%, #0000);
  background-size: 64px 64px;
  animation: uppy-StatusBar-ProgressStripes 1s linear infinite;
}

@keyframes uppy-StatusBar-ProgressStripes {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 64px 0;
  }
}

.uppy-StatusBar.is-preprocessing .uppy-StatusBar-progress, .uppy-StatusBar.is-postprocessing .uppy-StatusBar-progress {
  background-color: #f6a623;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-progress {
  display: none;
}

[dir="ltr"] .uppy-StatusBar-content {
  padding-left: 10px;
}

[dir="rtl"] .uppy-StatusBar-content {
  padding-right: 10px;
}

.uppy-StatusBar-content {
  z-index: 1002;
  height: 100%;
  color: #333;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  display: flex;
  position: relative;
}

[dir="ltr"] .uppy-size--md .uppy-StatusBar-content {
  padding-left: 15px;
}

[dir="rtl"] .uppy-size--md .uppy-StatusBar-content {
  padding-right: 15px;
}

[data-uppy-theme="dark"] .uppy-StatusBar-content {
  color: #eaeaea;
}

[dir="ltr"] .uppy-StatusBar-status {
  padding-right: .3em;
}

[dir="rtl"] .uppy-StatusBar-status {
  padding-left: .3em;
}

.uppy-StatusBar-status {
  flex-direction: column;
  justify-content: center;
  font-weight: normal;
  line-height: 1.4;
  display: flex;
}

.uppy-StatusBar-statusPrimary {
  font-weight: 500;
  line-height: 1;
  display: flex;
}

.uppy-StatusBar-statusPrimary button.uppy-StatusBar-details {
  margin-left: 5px;
}

[data-uppy-theme="dark"] .uppy-StatusBar-statusPrimary {
  color: #eaeaea;
}

.uppy-StatusBar-statusSecondary {
  color: #757575;
  white-space: nowrap;
  margin-top: 1px;
  font-size: 11px;
  line-height: 1.2;
  display: inline-block;
}

[data-uppy-theme="dark"] .uppy-StatusBar-statusSecondary {
  color: #bbb;
}

[dir="ltr"] .uppy-StatusBar-statusSecondaryHint {
  margin-right: 5px;
}

[dir="rtl"] .uppy-StatusBar-statusSecondaryHint {
  margin-left: 5px;
}

.uppy-StatusBar-statusSecondaryHint {
  vertical-align: middle;
  line-height: 1;
  display: inline-block;
}

[dir="ltr"] .uppy-size--md .uppy-StatusBar-statusSecondaryHint {
  margin-right: 8px;
}

[dir="rtl"] .uppy-size--md .uppy-StatusBar-statusSecondaryHint {
  margin-left: 8px;
}

[dir="ltr"] .uppy-StatusBar-statusIndicator {
  margin-right: 7px;
}

[dir="rtl"] .uppy-StatusBar-statusIndicator {
  margin-left: 7px;
}

.uppy-StatusBar-statusIndicator {
  color: #525252;
  position: relative;
  top: 1px;
}

.uppy-StatusBar-statusIndicator svg {
  vertical-align: text-bottom;
}

[dir="ltr"] .uppy-StatusBar-actions {
  right: 10px;
}

[dir="rtl"] .uppy-StatusBar-actions {
  left: 10px;
}

.uppy-StatusBar-actions {
  z-index: 1004;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actions {
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  padding: 0 15px;
  position: static;
}

[data-uppy-theme="dark"] .uppy-StatusBar.is-waiting .uppy-StatusBar-actions {
  background-color: #1f1f1f;
}

.uppy-StatusBar:not([aria-hidden="true"]).is-waiting.has-ghosts {
  height: 90px;
  flex-direction: column;
}

.uppy-size--md .uppy-StatusBar:not([aria-hidden="true"]).is-waiting.has-ghosts {
  height: 65px;
  flex-direction: row;
}

.uppy-StatusBar:not([aria-hidden="true"]).is-waiting.has-ghosts .uppy-StatusBar-actions {
  flex-direction: column;
  justify-content: center;
}

.uppy-size--md .uppy-StatusBar:not([aria-hidden="true"]).is-waiting.has-ghosts .uppy-StatusBar-actions {
  justify-content: initial;
  flex-direction: row;
}

.uppy-StatusBar-actionCircleBtn {
  cursor: pointer;
  opacity: .9;
  margin: 3px;
  line-height: 1;
}

.uppy-StatusBar-actionCircleBtn:focus {
  outline: none;
}

.uppy-StatusBar-actionCircleBtn::-moz-focus-inner {
  border: 0;
}

.uppy-StatusBar-actionCircleBtn:focus {
  box-shadow: 0 0 0 3px #2275d780;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionCircleBtn:focus {
  outline: none;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionCircleBtn::-moz-focus-inner {
  border: 0;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionCircleBtn:focus {
  box-shadow: 0 0 0 2px #aae1ffd9;
}

.uppy-StatusBar-actionCircleBtn:hover {
  opacity: 1;
}

.uppy-StatusBar-actionCircleBtn:focus {
  border-radius: 50%;
}

.uppy-StatusBar-actionCircleBtn svg {
  vertical-align: bottom;
}

.uppy-StatusBar-actionBtn {
  color: #2275d7;
  font-size: 10px;
  line-height: inherit;
  vertical-align: middle;
  display: inline-block;
}

.uppy-size--md .uppy-StatusBar-actionBtn {
  font-size: 11px;
}

.uppy-StatusBar-actionBtn--disabled {
  opacity: .4;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--disabled {
  opacity: .7;
}

[dir="ltr"] .uppy-StatusBar-actionBtn--retry {
  margin-right: 6px;
}

[dir="rtl"] .uppy-StatusBar-actionBtn--retry {
  margin-left: 6px;
}

.uppy-StatusBar-actionBtn--retry {
  height: 16px;
  color: #fff;
  background-color: #ff4b23;
  border-radius: 8px;
  padding: 1px 6px 3px 18px;
  line-height: 1;
  position: relative;
}

.uppy-StatusBar-actionBtn--retry:focus {
  outline: none;
}

.uppy-StatusBar-actionBtn--retry::-moz-focus-inner {
  border: 0;
}

.uppy-StatusBar-actionBtn--retry:focus {
  box-shadow: 0 0 0 3px #2275d780;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--retry:focus {
  outline: none;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--retry::-moz-focus-inner {
  border: 0;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--retry:focus {
  box-shadow: 0 0 0 2px #aae1ffd9;
}

.uppy-StatusBar-actionBtn--retry:hover {
  background-color: #f92d00;
}

[dir="ltr"] .uppy-StatusBar-actionBtn--retry svg {
  left: 6px;
}

[dir="rtl"] .uppy-StatusBar-actionBtn--retry svg {
  right: 6px;
}

.uppy-StatusBar-actionBtn--retry svg {
  position: absolute;
  top: 3px;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  width: 100%;
  color: #fff;
  background-color: #1bb240;
  padding: 15px 10px;
  font-size: 14px;
  line-height: 1;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload:hover {
  background-color: #189c38;
}

[data-uppy-theme="dark"] .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  background-color: #1c8b37;
}

[data-uppy-theme="dark"] .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload:hover {
  background-color: #18762f;
}

.uppy-size--md .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  width: auto;
  padding: 13px 22px;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload.uppy-StatusBar-actionBtn--disabled:hover {
  cursor: not-allowed;
  background-color: #1bb240;
}

[data-uppy-theme="dark"] .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload.uppy-StatusBar-actionBtn--disabled:hover {
  background-color: #1c8b37;
}

.uppy-StatusBar:not(.is-waiting) .uppy-StatusBar-actionBtn--upload {
  color: #2275d7;
  background-color: #0000;
}

[dir="ltr"] .uppy-StatusBar-actionBtn--uploadNewlyAdded {
  padding-right: 3px;
}

[dir="rtl"] .uppy-StatusBar-actionBtn--uploadNewlyAdded, [dir="ltr"] .uppy-StatusBar-actionBtn--uploadNewlyAdded {
  padding-left: 3px;
}

[dir="rtl"] .uppy-StatusBar-actionBtn--uploadNewlyAdded {
  padding-right: 3px;
}

.uppy-StatusBar-actionBtn--uploadNewlyAdded {
  border-radius: 3px;
  padding-bottom: 1px;
}

.uppy-StatusBar-actionBtn--uploadNewlyAdded:focus {
  outline: none;
}

.uppy-StatusBar-actionBtn--uploadNewlyAdded::-moz-focus-inner {
  border: 0;
}

.uppy-StatusBar-actionBtn--uploadNewlyAdded:focus {
  box-shadow: 0 0 0 3px #2275d780;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--uploadNewlyAdded:focus {
  outline: none;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--uploadNewlyAdded::-moz-focus-inner {
  border: 0;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--uploadNewlyAdded:focus {
  box-shadow: 0 0 0 2px #aae1ffd9;
}

.uppy-StatusBar.is-preprocessing .uppy-StatusBar-actionBtn--uploadNewlyAdded, .uppy-StatusBar.is-postprocessing .uppy-StatusBar-actionBtn--uploadNewlyAdded {
  display: none;
}

.uppy-StatusBar-actionBtn--done {
  border-radius: 3px;
  padding: 7px 8px;
  line-height: 1;
}

.uppy-StatusBar-actionBtn--done:focus {
  outline: none;
}

.uppy-StatusBar-actionBtn--done::-moz-focus-inner {
  border: 0;
}

.uppy-StatusBar-actionBtn--done:hover {
  color: #1b5dab;
}

.uppy-StatusBar-actionBtn--done:focus {
  background-color: #eceef2;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--done:focus {
  background-color: #333;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--done {
  color: #02baf2;
}

.uppy-size--md .uppy-StatusBar-actionBtn--done {
  font-size: 14px;
}

.uppy-StatusBar-serviceMsg {
  color: #000;
  padding-left: 10px;
  font-size: 11px;
  line-height: 1.1;
}

.uppy-size--md .uppy-StatusBar-serviceMsg {
  padding-left: 15px;
  font-size: 14px;
}

[data-uppy-theme="dark"] .uppy-StatusBar-serviceMsg {
  color: #eaeaea;
}

.uppy-StatusBar-serviceMsg-ghostsIcon {
  width: 10px;
  vertical-align: text-bottom;
  opacity: .5;
  position: relative;
  top: 2px;
  left: 6px;
}

.uppy-size--md .uppy-StatusBar-serviceMsg-ghostsIcon {
  width: 15px;
  top: 1px;
  left: 10px;
}

[dir="ltr"] .uppy-StatusBar-details {
  left: 2px;
}

[dir="rtl"] .uppy-StatusBar-details {
  right: 2px;
}

.uppy-StatusBar-details {
  width: 13px;
  height: 13px;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: help;
  appearance: none;
  background-color: #939393;
  border-radius: 50%;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  display: inline-block;
  position: relative;
  top: 0;
}

.uppy-StatusBar-details:after {
  word-wrap: break-word;
  line-height: 1.3;
}

[dir="ltr"] .uppy-StatusBar-spinner {
  margin-right: 10px;
}

[dir="rtl"] .uppy-StatusBar-spinner {
  margin-left: 10px;
}

.uppy-StatusBar-spinner {
  fill: #2275d7;
  animation-name: uppy-StatusBar-spinnerAnimation;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.uppy-StatusBar.is-preprocessing .uppy-StatusBar-spinner, .uppy-StatusBar.is-postprocessing .uppy-StatusBar-spinner {
  fill: #f6a623;
}

@keyframes uppy-StatusBar-spinnerAnimation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.uppy-ProviderBrowser-viewType--grid ul.uppy-ProviderBrowser-list, .uppy-ProviderBrowser-viewType--unsplash ul.uppy-ProviderBrowser-list {
  flex-flow: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 6px;
  display: flex;
}

.uppy-ProviderBrowser-viewType--grid ul.uppy-ProviderBrowser-list:after, .uppy-ProviderBrowser-viewType--unsplash ul.uppy-ProviderBrowser-list:after {
  content: "";
  flex: auto;
}

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem, .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem {
  width: 50%;
  margin: 0;
  position: relative;
}

.uppy-size--md .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem, .uppy-size--md .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem {
  width: 33.3333%;
}

.uppy-size--lg .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem, .uppy-size--lg .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem {
  width: 25%;
}

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem:before, .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem:before {
  content: "";
  padding-top: 100%;
  display: block;
}

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--selected img, .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--selected svg, .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--selected img, .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--selected svg {
  opacity: .85;
}

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--disabled, .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--disabled {
  opacity: .5;
}

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview .uppy-ProviderBrowserItem-inner, .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--noPreview .uppy-ProviderBrowserItem-inner {
  background-color: #93939333;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview .uppy-ProviderBrowserItem-inner, [data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--noPreview .uppy-ProviderBrowserItem-inner {
  background-color: #eaeaea33;
}

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview svg, .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--noPreview svg {
  width: 30%;
  height: 30%;
  fill: #000000b3;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview svg, [data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--noPreview svg {
  fill: #fffc;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner {
  width: calc(100% - 14px);
  height: calc(100% - 14px);
  text-align: center;
  border-radius: 4px;
  position: absolute;
  inset: 7px;
  overflow: hidden;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner .uppy.uppy-ProviderBrowserItem-inner-relative, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner .uppy.uppy-ProviderBrowserItem-inner-relative {
  position: relative;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author {
  width: 100%;
  color: #fff;
  background: #0000004d;
  margin: 0;
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author:hover, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author:hover {
  background: #0006;
  text-decoration: underline;
}

@media (hover: none) {
  .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author {
    display: block;
  }
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner, [data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner {
  box-shadow: 0 0 0 3px #aae1ffb3;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner img, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox {
  z-index: 1002;
  width: 26px;
  height: 26px;
  opacity: 0;
  background-color: #2275d7;
  border-radius: 50%;
  position: absolute;
  top: 16px;
  right: 16px;
}

[dir="ltr"] .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox:after, [dir="ltr"] .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox:after {
  left: 7px;
}

[dir="rtl"] .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox:after, [dir="rtl"] .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox:after {
  right: 7px;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox:after, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox:after {
  width: 12px;
  height: 7px;
  top: 8px;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--is-checked, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--is-checked {
  opacity: 1;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--grid:hover + label .uppy-ProviderBrowserItem-author, .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--grid:focus + label .uppy-ProviderBrowserItem-author, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--grid:hover + label .uppy-ProviderBrowserItem-author, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--grid:focus + label .uppy-ProviderBrowserItem-author {
  display: block;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--grid:focus + label, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--grid:focus + label {
  box-shadow: 0 0 0 3px #2275d780;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--grid:focus + label:focus, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--grid:focus + label:focus {
  outline: none;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--grid:focus + label::-moz-focus-inner, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--grid:focus + label::-moz-focus-inner {
  border: 0;
}

.uppy-ProviderBrowser-viewType--list {
  background-color: #fff;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--list {
  background-color: #1f1f1f;
}

.uppy-ProviderBrowser-viewType--list li.uppy-ProviderBrowserItem {
  align-items: center;
  margin: 0;
  padding: 7px 15px;
  display: flex;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--list li.uppy-ProviderBrowserItem {
  color: #eaeaea;
}

.uppy-ProviderBrowser-viewType--list li.uppy-ProviderBrowserItem--disabled {
  opacity: .6;
}

[dir="ltr"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox {
  margin-right: 15px;
}

[dir="rtl"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox {
  margin-left: 15px;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox {
  width: 17px;
  height: 17px;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 3px;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox:focus {
  border: 1px solid #2275d7;
  outline: none;
  box-shadow: 0 0 0 3px #2275d740;
}

[dir="ltr"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox:after {
  left: 3px;
}

[dir="rtl"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox:after {
  right: 3px;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox:after {
  width: 9px;
  height: 5px;
  opacity: 0;
  top: 4px;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox:focus {
  border-color: #02baf2b3;
  box-shadow: 0 0 0 3px #02baf233;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox--is-checked {
  background-color: #2275d7;
  border-color: #2275d7;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox--is-checked:after {
  opacity: 1;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner {
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  padding: 2px;
  display: flex;
  overflow: hidden;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner:focus {
  outline: none;
  text-decoration: underline;
}

[dir="ltr"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner img, [dir="ltr"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner svg {
  margin-right: 8px;
}

[dir="rtl"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner img, [dir="rtl"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner svg {
  margin-left: 8px;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner img, .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner svg {
  max-width: 20px;
  max-height: 20px;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner span {
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.2;
  overflow: hidden;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem--disabled .uppy-ProviderBrowserItem-inner {
  cursor: default;
}

[dir="ltr"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-iconWrap {
  margin-right: 7px;
}

[dir="rtl"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-iconWrap {
  margin-left: 7px;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-iconWrap {
  width: 20px;
}

.uppy-ProviderBrowserItem-checkbox {
  cursor: pointer;
  flex-shrink: 0;
  position: relative;
}

.uppy-ProviderBrowserItem-checkbox:disabled {
  cursor: default;
}

.uppy-ProviderBrowserItem-checkbox:after {
  cursor: pointer;
  content: "";
  border-bottom: 2px solid #eaeaea;
  border-left: 2px solid #eaeaea;
  position: absolute;
  transform: rotate(-45deg);
}

.uppy-ProviderBrowserItem-checkbox:disabled:after {
  cursor: default;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowserItem-checkbox {
  background-color: #1f1f1f;
  border-color: #939393;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowserItem-checkbox--is-checked {
  background-color: #333;
}

.uppy-SearchProvider {
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

[data-uppy-theme="dark"] .uppy-SearchProvider {
  background-color: #1f1f1f;
}

.uppy-SearchProvider-input {
  width: 90%;
  max-width: 650px;
  margin-bottom: 15px;
}

.uppy-size--md .uppy-SearchProvider-input {
  margin-bottom: 20px;
}

.uppy-SearchProvider-searchButton {
  padding: 13px 25px;
}

.uppy-size--md .uppy-SearchProvider-searchButton {
  padding: 13px 30px;
}

.uppy-DashboardContent-panelBody {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

[data-uppy-theme="dark"] .uppy-DashboardContent-panelBody {
  background-color: #1f1f1f;
}

.uppy-Provider-auth, .uppy-Provider-error, .uppy-Provider-loading, .uppy-Provider-empty {
  color: #939393;
  flex-flow: column wrap;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uppy-Provider-empty {
  color: #939393;
}

.uppy-Provider-authIcon svg {
  width: 100px;
  height: 75px;
  margin-bottom: 15px;
}

.uppy-Provider-authTitle {
  max-width: 500px;
  color: #757575;
  text-align: center;
  margin-bottom: 30px;
  padding: 0 15px;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.4;
}

.uppy-size--md .uppy-Provider-authTitle {
  font-size: 20px;
}

[data-uppy-theme="dark"] .uppy-Provider-authTitle {
  color: #cfcfcf;
}

.uppy-Provider-btn-google {
  background: #4285f4;
  align-items: center;
  display: flex;
  padding: 8px 12px !important;
}

.uppy-Provider-btn-google:hover {
  background-color: #1266f1;
}

.uppy-Provider-btn-google:focus {
  outline: none;
  box-shadow: 0 0 0 3px #4285f466;
}

.uppy-Provider-btn-google svg {
  margin-right: 8px;
}

[dir="ltr"] .uppy-Provider-breadcrumbs {
  text-align: left;
}

[dir="rtl"] .uppy-Provider-breadcrumbs {
  text-align: right;
}

.uppy-Provider-breadcrumbs {
  color: #525252;
  flex: 1;
  margin-bottom: 10px;
  font-size: 12px;
}

.uppy-size--md .uppy-Provider-breadcrumbs {
  margin-bottom: 0;
}

[data-uppy-theme="dark"] .uppy-Provider-breadcrumbs {
  color: #eaeaea;
}

[dir="ltr"] .uppy-Provider-breadcrumbsIcon {
  margin-right: 4px;
}

[dir="rtl"] .uppy-Provider-breadcrumbsIcon {
  margin-left: 4px;
}

.uppy-Provider-breadcrumbsIcon {
  color: #525252;
  vertical-align: middle;
  line-height: 1;
  display: inline-block;
}

.uppy-Provider-breadcrumbsIcon svg {
  width: 13px;
  height: 13px;
  fill: #525252;
}

.uppy-Provider-breadcrumbs button {
  line-height: inherit;
  border-radius: 3px;
  padding: 4px;
  display: inline-block;
}

.uppy-Provider-breadcrumbs button:focus {
  outline: none;
}

.uppy-Provider-breadcrumbs button::-moz-focus-inner {
  border: 0;
}

.uppy-Provider-breadcrumbs button:hover {
  color: #1b5dab;
}

.uppy-Provider-breadcrumbs button:focus {
  background-color: #eceef2;
}

[data-uppy-theme="dark"] .uppy-Provider-breadcrumbs button:focus {
  background-color: #333;
}

.uppy-Provider-breadcrumbs button:not(:last-of-type) {
  text-decoration: underline;
}

.uppy-Provider-breadcrumbs button:last-of-type {
  color: #333;
  cursor: normal;
  pointer-events: none;
  font-weight: 500;
}

.uppy-Provider-breadcrumbs button:hover {
  cursor: pointer;
}

[data-uppy-theme="dark"] .uppy-Provider-breadcrumbs button {
  color: #eaeaea;
}

.uppy-ProviderBrowser {
  height: 100%;
  flex-direction: column;
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  display: flex;
}

.uppy-ProviderBrowser-user {
  color: #333;
  margin: 0 8px 0 0;
  font-weight: 500;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-user {
  color: #eaeaea;
}

[dir="ltr"] .uppy-ProviderBrowser-user:after {
  left: 4px;
}

[dir="rtl"] .uppy-ProviderBrowser-user:after {
  right: 4px;
}

.uppy-ProviderBrowser-user:after {
  color: #939393;
  content: "·";
  font-weight: normal;
  position: relative;
}

.uppy-ProviderBrowser-header {
  z-index: 1001;
  border-bottom: 1px solid #eaeaea;
  position: relative;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-header {
  border-bottom: 1px solid #333;
}

.uppy-ProviderBrowser-headerBar {
  z-index: 1001;
  color: #757575;
  background-color: #fafafa;
  padding: 7px 15px;
  font-size: 12px;
  line-height: 1.4;
}

.uppy-size--md .uppy-ProviderBrowser-headerBar {
  align-items: center;
  display: flex;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-headerBar {
  background-color: #1f1f1f;
}

.uppy-ProviderBrowser-headerBar--simple {
  text-align: center;
  justify-content: center;
  display: block;
}

.uppy-ProviderBrowser-headerBar--simple .uppy-Provider-breadcrumbsWrap {
  vertical-align: middle;
  flex: none;
  display: inline-block;
}

.uppy-ProviderBrowser-filter {
  width: 100%;
  height: 30px;
  background-color: #fff;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  position: relative;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-filter {
  background-color: #1f1f1f;
}

[dir="ltr"] .uppy-ProviderBrowser-filterIcon {
  left: 16px;
}

[dir="rtl"] .uppy-ProviderBrowser-filterIcon {
  right: 16px;
}

.uppy-ProviderBrowser-filterIcon {
  z-index: 1002;
  width: 12px;
  height: 12px;
  color: #bbb;
  position: absolute;
}

[dir="ltr"] .uppy-ProviderBrowser-filterInput {
  padding-left: 27px;
}

[dir="rtl"] .uppy-ProviderBrowser-filterInput {
  padding-right: 27px;
}

.uppy-ProviderBrowser-filterInput {
  z-index: 1001;
  width: 100%;
  height: 30px;
  background-color: #0000;
  border: 0;
  border-radius: 4px;
  outline: 0;
  margin: 0 8px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Segoe UI Symbol, Segoe UI Emoji, Apple Color Emoji, Roboto, Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 1.4;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-filterInput {
  color: #eaeaea;
  background-color: #1f1f1f;
}

.uppy-ProviderBrowser-filterInput:focus {
  background-color: #f4f4f4;
  outline: 0;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-filterInput:focus {
  background-color: #333;
}

.uppy-ProviderBrowser-filterInput::placeholder {
  color: #939393;
  opacity: 1;
}

.uppy-ProviderBrowser-search {
  width: 100%;
  height: 30px;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 2px;
  display: flex;
  position: relative;
}

[dir="ltr"] .uppy-ProviderBrowser-searchInput {
  padding-left: 30px;
}

[dir="rtl"] .uppy-ProviderBrowser-searchInput {
  padding-right: 30px;
}

.uppy-ProviderBrowser-searchInput {
  z-index: 1001;
  width: 100%;
  height: 30px;
  color: #333;
  background-color: #eaeaea;
  border: 0;
  border-radius: 4px;
  outline: 0;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Segoe UI Symbol, Segoe UI Emoji, Apple Color Emoji, Roboto, Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 1.4;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-searchInput {
  color: #eaeaea;
  background-color: #1f1f1f;
}

.uppy-ProviderBrowser-searchInput:focus {
  background-color: #cfcfcf;
  outline: 0;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-searchInput:focus {
  background-color: #333;
}

[dir="ltr"] .uppy-ProviderBrowser-searchIcon {
  left: 10px;
}

[dir="rtl"] .uppy-ProviderBrowser-searchIcon {
  right: 10px;
}

.uppy-ProviderBrowser-searchIcon {
  z-index: 1002;
  width: 12px;
  height: 12px;
  color: #757575;
  position: absolute;
}

.uppy-ProviderBrowser-searchInput::placeholder {
  color: #939393;
  opacity: 1;
}

.uppy-ProviderBrowser-userLogout {
  color: #2275d7;
  line-height: inherit;
  cursor: pointer;
  border-radius: 3px;
  padding: 4px;
}

.uppy-ProviderBrowser-userLogout:focus {
  outline: none;
}

.uppy-ProviderBrowser-userLogout::-moz-focus-inner {
  border: 0;
}

.uppy-ProviderBrowser-userLogout:hover {
  color: #1b5dab;
}

.uppy-ProviderBrowser-userLogout:focus {
  background-color: #eceef2;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-userLogout:focus {
  background-color: #333;
}

.uppy-ProviderBrowser-userLogout:hover {
  text-decoration: underline;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-userLogout {
  color: #eaeaea;
}

.uppy-ProviderBrowser-body {
  flex: 1;
  position: relative;
}

.uppy-ProviderBrowser-list {
  width: 100%;
  height: 100%;
  border-spacing: 0;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  flex: 1;
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  position: absolute;
  inset: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-list {
  background-color: #1f1f1f;
}

.uppy-ProviderBrowser-list:focus {
  outline: none;
}

.uppy-ProviderBrowserItem-inner {
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
}

.uppy-ProviderBrowser-footer {
  height: 65px;
  background-color: #fff;
  border-top: 1px solid #eaeaea;
  align-items: center;
  padding: 0 15px;
  display: flex;
}

[dir="ltr"] .uppy-ProviderBrowser-footer button {
  margin-right: 8px;
}

[dir="rtl"] .uppy-ProviderBrowser-footer button {
  margin-left: 8px;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-footer {
  background-color: #1f1f1f;
  border-top: 1px solid #333;
}

.uppy-Dashboard-Item-previewInnerWrap {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 2px #0006;
}

.uppy-size--md .uppy-Dashboard-Item-previewInnerWrap {
  box-shadow: 0 1px 2px #00000026;
}

.uppy-Dashboard-Item-previewInnerWrap:after {
  z-index: 1001;
  content: "";
  background-color: #000000a6;
  display: none;
  position: absolute;
  inset: 0;
}

.uppy-Dashboard-Item-previewLink {
  z-index: 1002;
  position: absolute;
  inset: 0;
}

.uppy-Dashboard-Item-previewLink:focus {
  box-shadow: inset 0 0 0 3px #76abe9;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-previewLink:focus {
  box-shadow: inset 0 0 0 3px #016c8d;
}

.uppy-Dashboard-Item-preview img.uppy-Dashboard-Item-previewImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
  transform: translateZ(0);
}

.uppy-Dashboard-Item-progress {
  z-index: 1002;
  width: 120px;
  color: #fff;
  text-align: center;
  transition: all .35 ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.uppy-Dashboard-Item-progressIndicator {
  width: 38px;
  height: 38px;
  opacity: .9;
  color: #fff;
  display: inline-block;
}

.uppy-size--md .uppy-Dashboard-Item-progressIndicator {
  width: 55px;
  height: 55px;
}

button.uppy-Dashboard-Item-progressIndicator {
  cursor: pointer;
}

button.uppy-Dashboard-Item-progressIndicator:focus {
  outline: none;
}

button.uppy-Dashboard-Item-progressIndicator::-moz-focus-inner {
  border: 0;
}

button.uppy-Dashboard-Item-progressIndicator:focus .uppy-Dashboard-Item-progressIcon--bg, button.uppy-Dashboard-Item-progressIndicator:focus .uppy-Dashboard-Item-progressIcon--retry {
  fill: #76abe9;
}

.uppy-Dashboard-Item-progressIcon--circle {
  width: 100%;
  height: 100%;
}

.uppy-Dashboard-Item-progressIcon--bg {
  stroke: #fff6;
}

.uppy-Dashboard-Item-progressIcon--progress {
  stroke: #fff;
  transition: stroke-dashoffset .5s ease-out;
}

.uppy-Dashboard-Item-progressIcon--play {
  fill: #fff;
  stroke: #fff;
  transition: all .2s;
}

.uppy-Dashboard-Item-progressIcon--cancel {
  fill: #fff;
  transition: all .2s;
}

.uppy-Dashboard-Item-progressIcon--pause {
  fill: #fff;
  stroke: #fff;
  transition: all .2s;
}

.uppy-Dashboard-Item-progressIcon--check {
  fill: #fff;
  transition: all .2s;
}

.uppy-Dashboard-Item-progressIcon--retry {
  fill: #fff;
}

[dir="ltr"] .uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progress {
  right: -8px;
}

[dir="rtl"] .uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progress {
  left: -8px;
}

[dir="ltr"] .uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progress {
  left: initial;
}

[dir="rtl"] .uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progress {
  right: initial;
}

.uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progress {
  width: auto;
  transform: initial;
  top: -9px;
}

.uppy-Dashboard-Item.is-error .uppy-Dashboard-Item-progressIndicator {
  width: 18px;
  height: 18px;
}

.uppy-size--md .uppy-Dashboard-Item.is-error .uppy-Dashboard-Item-progressIndicator {
  width: 28px;
  height: 28px;
}

.uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progressIndicator {
  width: 18px;
  height: 18px;
  opacity: 1;
}

.uppy-size--md .uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progressIndicator {
  width: 22px;
  height: 22px;
}

.uppy-Dashboard-Item.is-processing .uppy-Dashboard-Item-progress {
  opacity: 0;
}

[dir="ltr"] .uppy-Dashboard-Item-fileInfo {
  padding-right: 5px;
}

[dir="rtl"] .uppy-Dashboard-Item-fileInfo {
  padding-left: 5px;
}

[dir="ltr"] .uppy-Dashboard--singleFile .uppy-Dashboard-Item-fileInfo {
  padding-right: 10px;
}

[dir="rtl"] .uppy-Dashboard--singleFile .uppy-Dashboard-Item-fileInfo {
  padding-left: 10px;
}

[dir="ltr"] .uppy-size--md.uppy-Dashboard--singleFile .uppy-Dashboard-Item-fileInfo {
  padding-right: 15px;
}

[dir="rtl"] .uppy-size--md.uppy-Dashboard--singleFile .uppy-Dashboard-Item-fileInfo {
  padding-left: 15px;
}

.uppy-Dashboard-Item-name {
  word-wrap: anywhere;
  word-break: break-all;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-name {
  color: #eaeaea;
}

.uppy-size--md.uppy-Dashboard--singleFile .uppy-Dashboard-Item-name {
  font-size: 14px;
  line-height: 1.4;
}

.uppy-Dashboard-Item-fileName {
  align-items: baseline;
  display: flex;
}

.uppy-Dashboard-Item-fileName button {
  margin-left: 5px;
}

.uppy-Dashboard-Item-author {
  color: #757575;
  vertical-align: bottom;
  margin-bottom: 5px;
  font-size: 11px;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

.uppy-Dashboard-Item-author a {
  color: #757575;
}

.uppy-Dashboard-Item-status {
  color: #757575;
  font-size: 11px;
  font-weight: normal;
  line-height: 1;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-status {
  color: #bbb;
}

.uppy-Dashboard-Item-statusSize {
  text-transform: uppercase;
  vertical-align: bottom;
  margin-bottom: 5px;
  display: inline-block;
}

.uppy-Dashboard-Item-reSelect {
  color: #2275d7;
  font-weight: 600;
  font-size: inherit;
  font-family: inherit;
}

.uppy-Dashboard-Item-errorMessage {
  color: #a51523;
  background-color: #fdeff1;
  padding: 5px 6px;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.3;
}

.uppy-Dashboard-Item-errorMessageBtn {
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
}

.uppy-Dashboard-Item-preview .uppy-Dashboard-Item-errorMessage {
  display: none;
}

.uppy-size--md .uppy-Dashboard-Item-preview .uppy-Dashboard-Item-errorMessage {
  border-top: 1px solid #f7c2c8;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 6px 8px;
  line-height: 1.4;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.uppy-Dashboard-Item-fileInfo .uppy-Dashboard-Item-errorMessage {
  border: 1px solid #f7c2c8;
  border-radius: 3px;
  display: inline-block;
  position: static;
}

.uppy-size--md .uppy-Dashboard-Item-fileInfo .uppy-Dashboard-Item-errorMessage {
  display: none;
}

.uppy-Dashboard-Item-action {
  color: #939393;
  cursor: pointer;
}

.uppy-Dashboard-Item-action:focus {
  outline: none;
}

.uppy-Dashboard-Item-action::-moz-focus-inner {
  border: 0;
}

.uppy-Dashboard-Item-action:focus {
  box-shadow: 0 0 0 3px #2275d780;
}

.uppy-Dashboard-Item-action:hover {
  color: #1f1f1f;
  opacity: 1;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-action {
  color: #cfcfcf;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-action:focus {
  outline: none;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-action::-moz-focus-inner {
  border: 0;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-action:focus {
  box-shadow: 0 0 0 2px #aae1ffd9;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-action:hover {
  color: #eaeaea;
}

.uppy-Dashboard-Item-action--remove {
  color: #1f1f1f;
  opacity: .95;
}

.uppy-Dashboard-Item-action--remove:hover {
  color: #000;
  opacity: 1;
}

[dir="ltr"] .uppy-size--md .uppy-Dashboard-Item-action--remove, [dir="ltr"] .uppy-Dashboard--singleFile .uppy-Dashboard-Item-action--remove {
  right: -8px;
}

[dir="rtl"] .uppy-size--md .uppy-Dashboard-Item-action--remove, [dir="rtl"] .uppy-Dashboard--singleFile .uppy-Dashboard-Item-action--remove {
  left: -8px;
}

.uppy-size--md .uppy-Dashboard-Item-action--remove, .uppy-Dashboard--singleFile .uppy-Dashboard-Item-action--remove {
  z-index: 1002;
  width: 18px;
  height: 18px;
  padding: 0;
  position: absolute;
  top: -8px;
}

.uppy-size--md .uppy-Dashboard-Item-action--remove:focus, .uppy-Dashboard--singleFile .uppy-Dashboard-Item-action--remove:focus {
  border-radius: 50%;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-action--remove {
  color: #525252;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-action--remove:hover {
  color: #333;
}

.uppy-Dashboard:not(.uppy-size--md):not(.uppy-Dashboard--singleFile) .uppy-Dashboard-Item-actionWrapper {
  align-items: center;
  display: flex;
}

.uppy-Dashboard:not(.uppy-size--md):not(.uppy-Dashboard--singleFile) .uppy-Dashboard-Item-action {
  width: 22px;
  height: 22px;
  margin-left: 3px;
  padding: 3px;
}

.uppy-Dashboard:not(.uppy-size--md):not(.uppy-Dashboard--singleFile) .uppy-Dashboard-Item-action:focus {
  border-radius: 3px;
}

.uppy-size--md .uppy-Dashboard-Item-action--copyLink, .uppy-size--md .uppy-Dashboard-Item-action--edit {
  width: 16px;
  height: 16px;
  padding: 0;
}

.uppy-size--md .uppy-Dashboard-Item-action--copyLink:focus, .uppy-size--md .uppy-Dashboard-Item-action--edit:focus {
  border-radius: 3px;
}

.uppy-Dashboard-Item {
  border-bottom: 1px solid #eaeaea;
  align-items: center;
  padding: 10px;
  display: flex;
}

[dir="ltr"] .uppy-Dashboard:not(.uppy-Dashboard--singleFile) .uppy-Dashboard-Item {
  padding-right: 0;
}

[dir="rtl"] .uppy-Dashboard:not(.uppy-Dashboard--singleFile) .uppy-Dashboard-Item {
  padding-left: 0;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item {
  border-bottom: 1px solid #333;
}

[dir="ltr"] .uppy-size--md .uppy-Dashboard-Item {
  float: left;
}

[dir="rtl"] .uppy-size--md .uppy-Dashboard-Item {
  float: right;
}

.uppy-size--md .uppy-Dashboard-Item {
  width: calc(33.333% - 30px);
  height: 215px;
  border-bottom: 0;
  margin: 5px 15px;
  padding: 0;
  display: block;
  position: relative;
}

.uppy-size--lg .uppy-Dashboard-Item {
  width: calc(25% - 30px);
  height: 190px;
  margin: 5px 15px;
  padding: 0;
}

.uppy-size--xl .uppy-Dashboard-Item {
  width: calc(20% - 30px);
  height: 210px;
  padding: 0;
}

.uppy-Dashboard--singleFile .uppy-Dashboard-Item {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-bottom: 0;
  margin: 10px;
  padding: 0;
  display: block;
  position: relative;
}

.uppy-Dashboard-Item.is-ghost .uppy-Dashboard-Item-previewInnerWrap {
  opacity: .2;
}

.uppy-Dashboard-Item.is-ghost .uppy-Dashboard-Item-name {
  opacity: .7;
}

.uppy-Dashboard-Item.is-ghost .uppy-Dashboard-Item-preview:before {
  z-index: 1005;
  opacity: .5;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='39' viewBox='0 0 35 39'%3E%3Cpath d='M1.708 38.66c1.709 0 3.417-3.417 6.834-3.417 3.416 0 5.125 3.417 8.61 3.417 3.348 0 5.056-3.417 8.473-3.417 4.305 0 5.125 3.417 6.833 3.417.889 0 1.709-.889 1.709-1.709v-19.68C34.167-5.757 0-5.757 0 17.271v19.68c0 .82.888 1.709 1.708 1.709zm8.542-17.084a3.383 3.383 0 01-3.417-3.416 3.383 3.383 0 013.417-3.417 3.383 3.383 0 013.417 3.417 3.383 3.383 0 01-3.417 3.416zm13.667 0A3.383 3.383 0 0120.5 18.16a3.383 3.383 0 013.417-3.417 3.383 3.383 0 013.416 3.417 3.383 3.383 0 01-3.416 3.416z' fill='%2523000' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-position: 50% 10px;
  background-repeat: no-repeat;
  background-size: 25px;
  position: absolute;
  inset: 0;
}

.uppy-size--md .uppy-Dashboard-Item.is-ghost .uppy-Dashboard-Item-preview:before {
  background-position: 50%;
  background-size: 40px;
}

.uppy-Dashboard-Item-preview {
  position: relative;
}

.uppy-Dashboard:not(.uppy-size--md):not(.uppy-Dashboard--singleFile) .uppy-Dashboard-Item-preview {
  width: 50px;
  height: 50px;
  flex-grow: 0;
  flex-shrink: 0;
}

.uppy-size--md .uppy-Dashboard-Item-preview {
  width: 100%;
  height: 140px;
}

.uppy-size--lg .uppy-Dashboard-Item-preview {
  height: 120px;
}

.uppy-size--xl .uppy-Dashboard-Item-preview {
  height: 140px;
}

.uppy-Dashboard--singleFile .uppy-Dashboard-Item-preview {
  width: 100%;
  height: 270px;
}

[dir="ltr"] .uppy-Dashboard-Item-fileInfoAndButtons {
  padding-right: 8px;
}

[dir="rtl"] .uppy-Dashboard-Item-fileInfoAndButtons {
  padding-left: 8px;
}

[dir="ltr"] .uppy-Dashboard-Item-fileInfoAndButtons {
  padding-left: 12px;
}

[dir="rtl"] .uppy-Dashboard-Item-fileInfoAndButtons {
  padding-right: 12px;
}

.uppy-Dashboard-Item-fileInfoAndButtons {
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.uppy-size--md .uppy-Dashboard-Item-fileInfoAndButtons, .uppy-Dashboard--singleFile .uppy-Dashboard-Item-fileInfoAndButtons {
  width: 100%;
  align-items: flex-start;
  padding: 9px 0 0;
}

.uppy-Dashboard-Item-fileInfo {
  flex-grow: 1;
  flex-shrink: 1;
}

.uppy-Dashboard-Item-actionWrapper {
  flex-grow: 0;
  flex-shrink: 0;
}

.uppy-Dashboard-Item.is-inprogress .uppy-Dashboard-Item-previewInnerWrap:after, .uppy-Dashboard-Item.is-error .uppy-Dashboard-Item-previewInnerWrap:after {
  display: block;
}

.uppy-Dashboard-Item.is-inprogress:not(.is-resumable) .uppy-Dashboard-Item-action--remove {
  display: none;
}

[dir="ltr"] .uppy-Dashboard-Item-errorDetails {
  left: 2px;
}

[dir="rtl"] .uppy-Dashboard-Item-errorDetails {
  right: 2px;
}

.uppy-Dashboard-Item-errorDetails {
  width: 13px;
  height: 13px;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: help;
  appearance: none;
  background-color: #939393;
  border: none;
  border-radius: 50%;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  display: inline-block;
  position: relative;
  top: 0;
}

.uppy-Dashboard-Item-errorDetails:after {
  word-wrap: break-word;
  line-height: 1.3;
}

.uppy-Dashboard-FileCard {
  z-index: 1005;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  flex-direction: column;
  display: flex;
  position: absolute;
  inset: 0;
  box-shadow: 0 0 10px 4px #0000001a;
}

.uppy-Dashboard-FileCard .uppy-DashboardContent-bar {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.uppy-Dashboard-FileCard .uppy-Dashboard-FileCard-actions {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.uppy-Dashboard-FileCard-inner {
  height: 100%;
  min-height: 0;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
}

.uppy-Dashboard-FileCard-preview {
  height: 60%;
  min-height: 0;
  border-bottom: 1px solid #eaeaea;
  flex-grow: 0;
  flex-shrink: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

[data-uppy-theme="dark"] .uppy-Dashboard-FileCard-preview {
  background-color: #333;
  border-bottom: 0;
}

.uppy-Dashboard-FileCard-preview img.uppy-Dashboard-Item-previewImg {
  max-width: 90%;
  max-height: 90%;
  object-fit: cover;
  border-radius: 3px;
  flex: none;
  box-shadow: 0 3px 20px #00000026;
}

[dir="ltr"] .uppy-Dashboard-FileCard-edit {
  right: 10px;
}

[dir="rtl"] .uppy-Dashboard-FileCard-edit {
  left: 10px;
}

.uppy-Dashboard-FileCard-edit {
  color: #fff;
  background-color: #00000080;
  border-radius: 50px;
  padding: 7px 15px;
  font-size: 13px;
  position: absolute;
  top: 10px;
}

.uppy-Dashboard-FileCard-edit:focus {
  outline: none;
}

.uppy-Dashboard-FileCard-edit::-moz-focus-inner {
  border: 0;
}

.uppy-Dashboard-FileCard-edit:focus {
  box-shadow: 0 0 0 3px #2275d780;
}

.uppy-Dashboard-FileCard-edit:hover {
  background-color: #000c;
}

.uppy-Dashboard-FileCard-info {
  height: 40%;
  -webkit-overflow-scrolling: touch;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 30px 20px 20px;
  overflow-y: auto;
}

[data-uppy-theme="dark"] .uppy-Dashboard-FileCard-info {
  background-color: #1f1f1f;
}

.uppy-Dashboard-FileCard-fieldset {
  max-width: 640px;
  border: 0;
  margin: auto auto 12px;
  padding: 0;
  font-size: 0;
}

.uppy-Dashboard-FileCard-label {
  width: 22%;
  color: #525252;
  vertical-align: middle;
  font-size: 12px;
  display: inline-block;
}

.uppy-size--md .uppy-Dashboard-FileCard-label {
  font-size: 14px;
}

[data-uppy-theme="dark"] .uppy-Dashboard-FileCard-label {
  color: #eaeaea;
}

.uppy-Dashboard-FileCard-input {
  width: 78%;
  vertical-align: middle;
  display: inline-block;
}

.uppy-Dashboard-FileCard-actions {
  height: 55px;
  background-color: #fafafa;
  border-top: 1px solid #eaeaea;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  padding: 0 15px;
  display: flex;
}

.uppy-size--md .uppy-Dashboard-FileCard-actions {
  height: 65px;
}

[data-uppy-theme="dark"] .uppy-Dashboard-FileCard-actions {
  background-color: #1f1f1f;
  border-top: 1px solid #333;
}

[dir="ltr"] .uppy-Dashboard-FileCard-actionsBtn {
  margin-right: 10px;
}

[dir="rtl"] .uppy-Dashboard-FileCard-actionsBtn {
  margin-left: 10px;
}

.uppy-transition-slideDownUp-enter {
  opacity: .01;
  transition: transform .25s ease-in-out, opacity .25s ease-in-out;
  transform: translate3d(0, -105%, 0);
}

.uppy-transition-slideDownUp-enter.uppy-transition-slideDownUp-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.uppy-transition-slideDownUp-leave {
  opacity: 1;
  transition: transform .25s ease-in-out, opacity .25s ease-in-out;
  transform: translate3d(0, 0, 0);
}

.uppy-transition-slideDownUp-leave.uppy-transition-slideDownUp-leave-active {
  opacity: .01;
  transform: translate3d(0, -105%, 0);
}

@keyframes uppy-Dashboard-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes uppy-Dashboard-fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes uppy-Dashboard-slideDownAndFadeIn {
  from {
    opacity: 0;
    transform: translate3d(-50%, -70%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0);
  }
}

@keyframes uppy-Dashboard-slideDownAndFadeIn--small {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes uppy-Dashboard-slideUpFadeOut {
  from {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-50%, -70%, 0);
  }
}

@keyframes uppy-Dashboard-slideUpFadeOut--small {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
}

.uppy-Dashboard--modal {
  z-index: 1001;
}

.uppy-Dashboard--modal[aria-hidden="true"] {
  display: none;
}

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose > .uppy-Dashboard-inner {
  animation: uppy-Dashboard-slideDownAndFadeIn--small .3s cubic-bezier(0, 0, .2, 1);
}

@media only screen and (min-width: 820px) {
  .uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose > .uppy-Dashboard-inner {
    animation: uppy-Dashboard-slideDownAndFadeIn .3s cubic-bezier(0, 0, .2, 1);
  }
}

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose > .uppy-Dashboard-overlay {
  animation: uppy-Dashboard-fadeIn .3s cubic-bezier(0, 0, .2, 1);
}

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing > .uppy-Dashboard-inner {
  animation: uppy-Dashboard-slideUpFadeOut--small .3s cubic-bezier(0, 0, .2, 1);
}

@media only screen and (min-width: 820px) {
  .uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing > .uppy-Dashboard-inner {
    animation: uppy-Dashboard-slideUpFadeOut .3s cubic-bezier(0, 0, .2, 1);
  }
}

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing > .uppy-Dashboard-overlay {
  animation: uppy-Dashboard-fadeOut .3s cubic-bezier(0, 0, .2, 1);
}

.uppy-Dashboard-isFixed {
  height: 100vh;
  overflow: hidden;
}

.uppy-Dashboard--modal .uppy-Dashboard-overlay {
  z-index: 1001;
  background-color: #00000080;
  position: fixed;
  inset: 0;
}

.uppy-Dashboard-inner {
  max-width: 100%;
  max-height: 100%;
  background-color: #fafafa;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  outline: none;
  position: relative;
}

.uppy-size--md .uppy-Dashboard-inner {
  min-height: auto;
}

@media only screen and (min-width: 820px) {
  .uppy-Dashboard-inner {
    width: 650px;
    height: 500px;
  }
}

.uppy-Dashboard--modal .uppy-Dashboard-inner {
  z-index: 1002;
}

[data-uppy-theme="dark"] .uppy-Dashboard-inner {
  background-color: #1f1f1f;
}

.uppy-Dashboard--isDisabled .uppy-Dashboard-inner {
  cursor: not-allowed;
}

.uppy-Dashboard-innerWrap {
  height: 100%;
  opacity: 0;
  border-radius: 5px;
  flex-direction: column;
  display: flex;
  position: relative;
  overflow: hidden;
}

.uppy-Dashboard--isInnerWrapVisible .uppy-Dashboard-innerWrap {
  opacity: 1;
}

.uppy-Dashboard--isDisabled .uppy-Dashboard-innerWrap {
  opacity: .6;
  filter: grayscale();
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}

.uppy-Dashboard--isDisabled .uppy-ProviderIconBg {
  fill: #9f9f9f;
}

.uppy-Dashboard--modal .uppy-Dashboard-inner {
  border: none;
  position: fixed;
  inset: 35px 15px 15px;
}

@media only screen and (min-width: 820px) {
  .uppy-Dashboard--modal .uppy-Dashboard-inner {
    top: 50%;
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%);
    box-shadow: 0 5px 15px 4px #00000026;
  }
}

[dir="ltr"] .uppy-Dashboard-close {
  right: -2px;
}

[dir="rtl"] .uppy-Dashboard-close {
  left: -2px;
}

.uppy-Dashboard-close {
  z-index: 1005;
  color: #ffffffe6;
  cursor: pointer;
  font-size: 27px;
  display: block;
  position: absolute;
  top: -33px;
}

.uppy-Dashboard-close:focus {
  outline: none;
}

.uppy-Dashboard-close::-moz-focus-inner {
  border: 0;
}

.uppy-Dashboard-close:focus {
  color: #8cb8ed;
}

@media only screen and (min-width: 820px) {
  [dir="ltr"] .uppy-Dashboard-close {
    right: -35px;
  }

  [dir="rtl"] .uppy-Dashboard-close {
    left: -35px;
  }

  .uppy-Dashboard-close {
    font-size: 35px;
    top: -10px;
  }
}

.uppy-Dashboard-serviceMsg {
  z-index: 1004;
  background-color: #fffbf7;
  border-top: 1px solid #edd4b9;
  border-bottom: 1px solid #edd4b9;
  padding: 12px 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
  position: relative;
  top: -1px;
}

.uppy-size--md .uppy-Dashboard-serviceMsg {
  font-size: 14px;
  line-height: 1.4;
}

[data-uppy-theme="dark"] .uppy-Dashboard-serviceMsg {
  color: #eaeaea;
  background-color: #1f1f1f;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
}

.uppy-Dashboard-serviceMsg-title {
  margin-bottom: 4px;
  padding-left: 42px;
  line-height: 1;
  display: block;
}

.uppy-Dashboard-serviceMsg-text {
  padding: 0 15px;
}

.uppy-Dashboard-serviceMsg-actionBtn {
  color: #2275d7;
  font-weight: inherit;
  font-size: inherit;
  vertical-align: initial;
}

[data-uppy-theme="dark"] .uppy-Dashboard-serviceMsg-actionBtn {
  color: #02baf2e6;
}

.uppy-Dashboard-serviceMsg-icon {
  position: absolute;
  top: 10px;
  left: 15px;
}

.uppy-Dashboard-AddFiles {
  height: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

[data-uppy-drag-drop-supported="true"] .uppy-Dashboard-AddFiles {
  height: calc(100% - 14px);
  border: 1px dashed #dfdfdf;
  border-radius: 3px;
  margin: 7px;
}

.uppy-Dashboard-AddFilesPanel .uppy-Dashboard-AddFiles {
  height: calc(100% - 54px);
  border: none;
}

.uppy-Dashboard--modal .uppy-Dashboard-AddFiles {
  border-color: #cfcfcf;
}

[data-uppy-theme="dark"] .uppy-Dashboard-AddFiles {
  border-color: #757575;
}

.uppy-Dashboard-AddFiles-info {
  margin-top: auto;
  padding-top: 15px;
  padding-bottom: 15px;
  display: none;
}

.uppy-size--height-md .uppy-Dashboard-AddFiles-info {
  display: block;
}

.uppy-size--md .uppy-Dashboard-AddFiles-info {
  padding-top: 30px;
  padding-bottom: 0;
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
}

[data-uppy-num-acquirers="0"] .uppy-Dashboard-AddFiles-info {
  margin-top: 0;
}

.uppy-Dashboard-browse {
  color: #2275d7e6;
  cursor: pointer;
}

.uppy-Dashboard-browse:focus {
  outline: none;
}

.uppy-Dashboard-browse::-moz-focus-inner {
  border: 0;
}

.uppy-Dashboard-browse:hover, .uppy-Dashboard-browse:focus {
  border-bottom: 1px solid #2275d7;
}

[data-uppy-theme="dark"] .uppy-Dashboard-browse {
  color: #02baf2e6;
}

[data-uppy-theme="dark"] .uppy-Dashboard-browse:hover, [data-uppy-theme="dark"] .uppy-Dashboard-browse:focus {
  border-bottom: 1px solid #02baf2;
}

.uppy-Dashboard-browseBtn {
  width: 100%;
  margin-top: 8px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  display: block;
}

.uppy-size--md .uppy-Dashboard-browseBtn {
  width: auto;
  margin: 15px auto;
  padding: 13px 44px;
  font-size: 15px;
}

.uppy-Dashboard-AddFiles-list {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  flex-direction: column;
  flex: 1;
  margin-top: 2px;
  padding: 2px 0;
  display: flex;
  overflow-y: auto;
}

.uppy-size--md .uppy-Dashboard-AddFiles-list {
  max-width: 600px;
  flex-flow: wrap;
  flex: none;
  justify-content: center;
  margin-top: 15px;
  padding-top: 0;
  overflow-y: visible;
}

.uppy-DashboardTab {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #eaeaea;
}

[data-uppy-theme="dark"] .uppy-DashboardTab {
  border-bottom: 1px solid #333;
}

.uppy-size--md .uppy-DashboardTab {
  width: initial;
  border-bottom: none;
  margin-bottom: 10px;
  display: inline-block;
}

.uppy-DashboardTab-btn {
  width: 100%;
  height: 100%;
  color: #525252;
  cursor: pointer;
  appearance: none;
  background-color: #0000;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 12px 15px;
}

.uppy-DashboardTab-btn:focus {
  outline: none;
}

.uppy-DashboardTab-btn::-moz-focus-inner {
  border: 0;
}

[dir="ltr"] .uppy-size--md .uppy-DashboardTab-btn {
  margin-right: 1px;
}

[dir="rtl"] .uppy-size--md .uppy-DashboardTab-btn {
  margin-left: 1px;
}

.uppy-size--md .uppy-DashboardTab-btn {
  width: 86px;
  border-radius: 5px;
  flex-direction: column;
  padding: 10px 3px;
}

[data-uppy-theme="dark"] .uppy-DashboardTab-btn {
  color: #eaeaea;
}

.uppy-DashboardTab-btn::-moz-focus-inner {
  border: 0;
}

.uppy-DashboardTab-btn:hover {
  background-color: #f1f3f6;
}

[data-uppy-theme="dark"] .uppy-DashboardTab-btn:hover {
  background-color: #333;
}

.uppy-DashboardTab-btn:active, .uppy-DashboardTab-btn:focus {
  background-color: #eceef2;
}

[data-uppy-theme="dark"] .uppy-DashboardTab-btn:active, [data-uppy-theme="dark"] .uppy-DashboardTab-btn:focus {
  background-color: #525252;
}

[dir="ltr"] .uppy-DashboardTab-btn svg {
  margin-right: 10px;
}

[dir="rtl"] .uppy-DashboardTab-btn svg {
  margin-left: 10px;
}

.uppy-DashboardTab-btn svg {
  max-width: 100%;
  max-height: 100%;
  vertical-align: text-top;
  transition: transform .15s ease-in-out;
  display: inline-block;
  overflow: hidden;
}

[dir="ltr"] .uppy-size--md .uppy-DashboardTab-btn svg {
  margin-right: 0;
}

[dir="rtl"] .uppy-size--md .uppy-DashboardTab-btn svg {
  margin-left: 0;
}

.uppy-DashboardTab-name {
  font-size: 14px;
  font-weight: 500;
}

.uppy-size--md .uppy-DashboardTab-name {
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 15px;
}

.uppy-DashboardTab svg {
  width: 23px;
  height: 23px;
  vertical-align: middle;
}

.uppy-size--md .uppy-DashboardTab svg {
  width: 30px;
  height: 30px;
}

.uppy-Dashboard-input {
  z-index: -1;
  width: .1px;
  height: .1px;
  opacity: 0;
  position: absolute;
  overflow: hidden;
}

.uppy-DashboardContent-bar {
  z-index: 1004;
  width: 100%;
  height: 40px;
  background-color: #fafafa;
  border-bottom: 1px solid #eaeaea;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.uppy-size--md .uppy-DashboardContent-bar {
  height: 50px;
  padding: 0 15px;
}

[data-uppy-theme="dark"] .uppy-DashboardContent-bar {
  background-color: #1f1f1f;
  border-bottom: 1px solid #333;
}

.uppy-DashboardContent-title {
  width: 100%;
  max-width: 170px;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
  margin: auto;
  font-size: 12px;
  font-weight: 500;
  line-height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
}

.uppy-size--md .uppy-DashboardContent-title {
  max-width: 300px;
  font-size: 14px;
  line-height: 50px;
}

[data-uppy-theme="dark"] .uppy-DashboardContent-title {
  color: #eaeaea;
}

[dir="ltr"] .uppy-DashboardContent-back, [dir="ltr"] .uppy-DashboardContent-save {
  margin-left: -6px;
}

[dir="rtl"] .uppy-DashboardContent-back, [dir="rtl"] .uppy-DashboardContent-save {
  margin-right: -6px;
}

.uppy-DashboardContent-back, .uppy-DashboardContent-save {
  color: inherit;
  font-size: inherit;
  -webkit-appearance: none;
  color: #2275d7;
  cursor: pointer;
  background: none;
  border: 0;
  border-radius: 3px;
  margin: 0;
  padding: 7px 6px;
  font-family: inherit;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
}

.uppy-DashboardContent-back:focus, .uppy-DashboardContent-save:focus {
  outline: none;
}

.uppy-DashboardContent-back::-moz-focus-inner, .uppy-DashboardContent-save::-moz-focus-inner {
  border: 0;
}

.uppy-DashboardContent-back:hover, .uppy-DashboardContent-save:hover {
  color: #1b5dab;
}

.uppy-DashboardContent-back:focus, .uppy-DashboardContent-save:focus {
  background-color: #eceef2;
}

[data-uppy-theme="dark"] .uppy-DashboardContent-back:focus, [data-uppy-theme="dark"] .uppy-DashboardContent-save:focus {
  background-color: #333;
}

.uppy-size--md .uppy-DashboardContent-back, .uppy-size--md .uppy-DashboardContent-save {
  font-size: 14px;
}

[data-uppy-theme="dark"] .uppy-DashboardContent-back, [data-uppy-theme="dark"] .uppy-DashboardContent-save {
  color: #02baf2;
}

[dir="ltr"] .uppy-DashboardContent-addMore {
  margin-right: -5px;
}

[dir="rtl"] .uppy-DashboardContent-addMore {
  margin-left: -5px;
}

.uppy-DashboardContent-addMore {
  color: inherit;
  font-size: inherit;
  -webkit-appearance: none;
  width: 29px;
  height: 29px;
  color: #2275d7;
  cursor: pointer;
  background: none;
  border: 0;
  border-radius: 3px;
  margin: 0;
  padding: 7px 8px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1;
}

.uppy-DashboardContent-addMore:focus {
  outline: none;
}

.uppy-DashboardContent-addMore::-moz-focus-inner {
  border: 0;
}

.uppy-DashboardContent-addMore:hover {
  color: #1b5dab;
}

.uppy-DashboardContent-addMore:focus {
  background-color: #eceef2;
}

[data-uppy-theme="dark"] .uppy-DashboardContent-addMore:focus {
  background-color: #333;
}

[dir="ltr"] .uppy-size--md .uppy-DashboardContent-addMore {
  margin-right: -8px;
}

[dir="rtl"] .uppy-size--md .uppy-DashboardContent-addMore {
  margin-left: -8px;
}

.uppy-size--md .uppy-DashboardContent-addMore {
  width: auto;
  height: auto;
  font-size: 14px;
}

[data-uppy-theme="dark"] .uppy-DashboardContent-addMore {
  color: #02baf2;
}

[dir="ltr"] .uppy-DashboardContent-addMore svg {
  margin-right: 4px;
}

[dir="rtl"] .uppy-DashboardContent-addMore svg {
  margin-left: 4px;
}

.uppy-DashboardContent-addMore svg {
  vertical-align: baseline;
}

.uppy-size--md .uppy-DashboardContent-addMore svg {
  width: 11px;
  height: 11px;
}

.uppy-DashboardContent-addMoreCaption {
  display: none;
}

.uppy-size--md .uppy-DashboardContent-addMoreCaption {
  display: inline;
}

.uppy-DashboardContent-panel {
  z-index: 1005;
  background-color: #f5f5f5;
  border-radius: 5px;
  flex-direction: column;
  flex: 1;
  display: flex;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.uppy-Dashboard-AddFilesPanel {
  z-index: 1005;
  background: linear-gradient(0deg, #fafafa 35%, #fafafad9 100%);
  border-radius: 5px;
  flex-direction: column;
  display: flex;
  position: absolute;
  inset: 0;
  overflow: hidden;
  box-shadow: 0 0 10px 5px #00000026;
}

[data-uppy-theme="dark"] .uppy-Dashboard-AddFilesPanel {
  background-color: #333;
  background-image: linear-gradient(0deg, #1f1f1f 35%, #1f1f1fd9 100%);
}

.uppy-Dashboard--isAddFilesPanelVisible .uppy-Dashboard-files {
  filter: blur(2px);
}

.uppy-Dashboard-progress {
  width: 100%;
  height: 12%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.uppy-Dashboard-progressBarContainer.is-active {
  z-index: 1004;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.uppy-Dashboard-filesContainer {
  flex: 1;
  margin: 0;
  position: relative;
  overflow-y: hidden;
}

.uppy-Dashboard-filesContainer:after {
  clear: both;
  content: "";
  display: table;
}

.uppy-Dashboard-files {
  -webkit-overflow-scrolling: touch;
  flex: 1;
  margin: 0;
  padding: 0 0 10px;
  overflow-y: auto;
}

.uppy-size--md .uppy-Dashboard-files {
  padding-top: 10px;
}

.uppy-Dashboard--singleFile .uppy-Dashboard-filesInner {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uppy-Dashboard-dropFilesHereHint {
  z-index: 2000;
  color: #757575;
  text-align: center;
  visibility: hidden;
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 1v1C11.85 2 2 11.85 2 24s9.85 22 22 22 22-9.85 22-22S36.15 2 24 2V1zm0 0V0c13.254 0 24 10.746 24 24S37.254 48 24 48 0 37.254 0 24 10.746 0 24 0v1zm7.707 19.293a.999.999 0 1 1-1.414 1.414L25 16.414V34a1 1 0 1 1-2 0V16.414l-5.293 5.293a.999.999 0 1 1-1.414-1.414l7-7a.999.999 0 0 1 1.414 0l7 7z' fill='%232275D7' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-position: 50%;
  background-repeat: no-repeat;
  border: 1px dashed #2275d7;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  padding-top: 90px;
  font-size: 16px;
  display: flex;
  position: absolute;
  inset: 7px;
}

[data-uppy-theme="dark"] .uppy-Dashboard-dropFilesHereHint {
  color: #bbb;
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 1v1C11.85 2 2 11.85 2 24s9.85 22 22 22 22-9.85 22-22S36.15 2 24 2V1zm0 0V0c13.254 0 24 10.746 24 24S37.254 48 24 48 0 37.254 0 24 10.746 0 24 0v1zm7.707 19.293a.999.999 0 1 1-1.414 1.414L25 16.414V34a1 1 0 1 1-2 0V16.414l-5.293 5.293a.999.999 0 1 1-1.414-1.414l7-7a.999.999 0 0 1 1.414 0l7 7z' fill='%2302BAF2' fill-rule='nonzero'/%3E%3C/svg%3E");
  border-color: #02baf2;
}

.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-dropFilesHereHint {
  visibility: visible;
}

.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-DashboardContent-bar, .uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-files, .uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-progressindicators, .uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-serviceMsg {
  opacity: .15;
}

.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-AddFiles {
  opacity: .03;
}

.uppy-Dashboard-AddFiles-title {
  width: 100%;
  color: #000;
  text-align: inline-start;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 0 15px;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.35;
}

.uppy-size--md .uppy-Dashboard-AddFiles-title {
  max-width: 480px;
  text-align: center;
  margin-top: 5px;
  padding: 0 35px;
  font-size: 25px;
  font-weight: 400;
}

[data-uppy-num-acquirers="0"] .uppy-Dashboard-AddFiles-title {
  text-align: center;
}

[data-uppy-theme="dark"] .uppy-Dashboard-AddFiles-title {
  color: #eaeaea;
}

.uppy-Dashboard-AddFiles-title button {
  font-weight: 500;
}

.uppy-size--md .uppy-Dashboard-AddFiles-title button {
  font-weight: 400;
}

.uppy-Dashboard-note {
  max-width: 350px;
  color: #757575;
  text-align: center;
  margin: auto;
  padding: 0 15px;
  font-size: 14px;
  line-height: 1.25;
}

.uppy-size--md .uppy-Dashboard-note {
  max-width: 600px;
  font-size: 15px;
  line-height: 1.35;
}

[data-uppy-theme="dark"] .uppy-Dashboard-note {
  color: #cfcfcf;
}

a.uppy-Dashboard-poweredBy {
  color: #939393;
  text-align: center;
  margin-top: 8px;
  font-size: 11px;
  text-decoration: none;
  display: inline-block;
}

.uppy-Dashboard-poweredByIcon {
  vertical-align: text-top;
  opacity: .9;
  fill: none;
  stroke: #939393;
  margin-left: 1px;
  margin-right: 1px;
  position: relative;
  top: 1px;
}

.uppy-Dashboard-Item-previewIcon {
  z-index: 100;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.uppy-size--md .uppy-Dashboard-Item-previewIcon {
  width: 38px;
  height: 38px;
}

.uppy-Dashboard-Item-previewIcon svg {
  width: 100%;
  height: 100%;
}

.uppy-Dashboard--singleFile .uppy-Dashboard-Item-previewIcon {
  width: 90px;
  height: 90px;
}

.uppy-Dashboard-Item-previewIconWrap {
  height: 76px;
  max-height: 75%;
  position: relative;
}

.uppy-Dashboard--singleFile .uppy-Dashboard-Item-previewIconWrap {
  height: 176px;
}

.uppy-Dashboard-Item-previewIconBg {
  width: 100%;
  height: 100%;
  filter: drop-shadow(0 1px 1px #0000001a);
}

.uppy-Dashboard-upload {
  width: 50px;
  height: 50px;
  position: relative;
}

.uppy-size--md .uppy-Dashboard-upload {
  width: 60px;
  height: 60px;
}

.uppy-Dashboard-upload .uppy-c-icon {
  width: 50%;
  position: relative;
  top: 1px;
}

[dir="ltr"] .uppy-Dashboard-uploadCount {
  right: -12px;
}

[dir="rtl"] .uppy-Dashboard-uploadCount {
  left: -12px;
}

.uppy-Dashboard-uploadCount {
  width: 16px;
  height: 16px;
  color: #fff;
  background-color: #1bb240;
  border-radius: 50%;
  font-size: 8px;
  line-height: 16px;
  position: absolute;
  top: -12px;
}

.uppy-size--md .uppy-Dashboard-uploadCount {
  width: 18px;
  height: 18px;
  font-size: 9px;
  line-height: 18px;
}

/*# sourceMappingURL=index.97fb8b1c.css.map */

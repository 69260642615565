/**
* General Uppy styles that apply to everything inside the .uppy-Root container
*/
.uppy-Root {
  position: relative;
  box-sizing: border-box;
  color: #333;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Segoe UI Symbol", "Segoe UI Emoji", "Apple Color Emoji", Roboto, Helvetica, Arial, sans-serif;
  line-height: 1;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[dir=rtl] .uppy-Root,
.uppy-Root[dir=rtl] {
  text-align: right;
}

.uppy-Root *,
.uppy-Root *::before,
.uppy-Root *::after {
  box-sizing: inherit;
}

.uppy-Root [hidden] {
  display: none;
}

.uppy-u-reset {
  all: initial;
  box-sizing: border-box;
  -webkit-appearance: none;
          appearance: none;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Segoe UI Symbol", "Segoe UI Emoji", "Apple Color Emoji", Roboto, Helvetica, Arial, sans-serif;
  line-height: 1;
}

[dir=rtl] .uppy-u-reset {
  text-align: right;
}

.uppy-c-textInput {
  padding: 6px 8px;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.uppy-size--md .uppy-c-textInput {
  padding: 8px 10px;
}
.uppy-c-textInput:focus {
  border-color: rgba(34, 117, 215, 0.6);
  outline: none;
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.15);
}
[data-uppy-theme=dark] .uppy-c-textInput {
  color: #eaeaea;
  background-color: #333;
  border-color: #333;
}
[data-uppy-theme=dark] .uppy-c-textInput:focus {
  border-color: #525252;
  box-shadow: none;
}

.uppy-c-icon {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  fill: currentColor;
}

.uppy-c-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  line-height: 1;
  white-space: nowrap;
  text-align: center;
  transition-duration: 0.3s;
  transition-property: background-color, color;
  -webkit-user-select: none;
          user-select: none;
}
[dir=rtl] .uppy-c-btn {
  text-align: center;
}

.uppy-c-btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.uppy-c-btn::-moz-focus-inner {
  border: 0;
}

.uppy-c-btn-primary {
  padding: 10px 18px;
  color: #fff;
  font-size: 14px;
  background-color: #2275d7;
  border-radius: 4px;
}
.uppy-c-btn-primary:hover {
  background-color: #1b5dab;
}
.uppy-c-btn-primary:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.4);
}
.uppy-size--md .uppy-c-btn-primary {
  padding: 13px 22px;
}
[data-uppy-theme=dark] .uppy-c-btn-primary {
  color: #eaeaea;
}
[data-uppy-theme=dark] .uppy-c-btn-primary:focus {
  outline: none;
}
[data-uppy-theme=dark] .uppy-c-btn-primary::-moz-focus-inner {
  border: 0;
}
[data-uppy-theme=dark] .uppy-c-btn-primary:focus {
  box-shadow: 0 0 0 2px rgba(170, 225, 255, 0.85);
}

.uppy-c-btn-link {
  padding: 10px 15px;
  color: #525252;
  font-size: 14px;
  line-height: 1;
  background-color: transparent;
  border-radius: 4px;
}
.uppy-c-btn-link:hover {
  color: #333;
}
.uppy-c-btn-link:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.25);
}
.uppy-size--md .uppy-c-btn-link {
  padding: 13px 18px;
}
[data-uppy-theme=dark] .uppy-c-btn-link {
  color: #eaeaea;
}
[data-uppy-theme=dark] .uppy-c-btn-link:focus {
  outline: none;
}
[data-uppy-theme=dark] .uppy-c-btn-link::-moz-focus-inner {
  border: 0;
}
[data-uppy-theme=dark] .uppy-c-btn-link:focus {
  box-shadow: 0 0 0 2px rgba(170, 225, 255, 0.85);
}
[data-uppy-theme=dark] .uppy-c-btn-link:hover {
  color: #939393;
}